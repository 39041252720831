import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Table, Dropdown } from 'semantic-ui-react';
import Image from "components/shared/Image";
import { CallsTemplate } from 'components/CallsTemplate';
import FilterIcon from "assets/images/filter_icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import CrossIcon from "assets/images/filter-close.svg";
import SortIcon from "assets/images/sort-icon.svg";
import SortAsc from "assets/images/sort-asc.svg";
import SortDesc from "assets/images/sort-desc.svg";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import ExpandIcon from "assets/images/calls-expand.svg";
import CollapseIcon from "assets/images/calls-collapse.svg";
import WhiteCrossIcon from "assets/images/white-cross.svg";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import DownloadIcon from "assets/images/download-icon.svg";
import { calculateTimeDifference } from "utils/helpers/helpers";
import { formatDate } from "utils/formatDate/formatDate";
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import { selectors as requestCallsSelectors } from 'redux/reducers/calls/calls_reducer';
import { selectors as callsFilterSelectors } from 'redux/reducers/callsFilters/callsFilters_reducer';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCallsFiltersAPIData } from 'redux/actions/callsFilters/callsFilters.actions';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import { ExpertProfile } from "components/ExpertProfile";
import { ScheduleCall } from "components/ScheduleCall";
import { getExpReceivedAPIData } from 'redux/actions/expertReceived/expertReceived.actions';
import { getDuplicateExpAPIData } from "redux/actions/DuplicateExpertProfiles/duplicateExpertProfiles.actions";
import { getExpRecAPIData } from 'redux/actions/expRecFilters/expRecFilters.actions';
import './RequestCalls.scss';

export const RequestCalls = ({ interviewId, hideCallRec, caseTeamMembers, isClosedRequest }) => {

    const dispatch = useDispatch();
    const callsSelectorData = useSelector(requestCallsSelectors?.getCallsData);
    const noRecFound = useSelector(requestCallsSelectors?.getNoRecordFound);
    const loading = useSelector(requestCallsSelectors?.getLoading);
    const callsFilters = useSelector(callsFilterSelectors?.getCallsFiltersResults);
    const [expandedData, setExpandedData] = useState(null);
    const [total, setTotal] = useState(0);
    const [userTimezone, setUserTimezone] = useState('Asia/Kolkata');
    const [expandedDataResp, setExpandedDataResp] = useState([]);
    const [sortState, setSortState] = useState(null);
    const [relevantCompanySearchTerm, setRelevantCompanySearchTerm] = useState('');

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [getSelectedTagsStatus, setSelectedTagsStatus] = useState([]);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [clickOutside, setClickOutside] = useState(false);
    const [showCrossIcon, setShowCrossIcon] = useState({
        'expertName': false,
        'expertTypes': false,
        'relevantCompanies': false,
        'networkProviders': false,
        'status': false,
    });
    const dropdownRefs = {
        expertName: useRef(null),
        expertTypes: useRef(null),
        relevantCompanies: useRef(null),
        networkProviders: useRef(null),
        status: useRef(null),
    };
    const [dropdownOpen, setDropdownOpen] = useState({
        expertName: false,
        expertTypes: false,
        relevantCompanies: false,
        networkProviders: false,
        status: false,
    });
    const [expertTypesFilters, setExpertTypesFilters] = useState([]);
    const [relevantCompaniesFilters, setRelevantCompaniesFilters] = useState([]);
    const [expertNameSearchTerm, setExpertNameSearchTerm] = useState('');
    const [relevantCompaniesInitialFilters, setRelevantCompaniesInitialFilters] = useState([]);
    const [networkProvidersFilters, setNetworkProvidersFilters] = useState([]);
    const [statusFilters, setStatusFilters] = useState([]);
    const [getFollowUpCalls, setFollowUpCalls] = useState(null);

    const [filters, setFilters] = useState({
        'expertName': '',
        'expertTypes': [],
        'relevantCompanies': [],
        'status': [],
        'networkProviders': [],
    });
    const [crossIconClick, setCrossIconClick] = useState(false);
    const isInitialMount = useRef(true);
    const [openScheduleCall, setOpenScheduleCall] = useState(false);
    const [openComparePopup, setOpenComparePopup] = useState({
        isOpen: false,
        type: '',
        selection: []
    });
    const [openExpertProfileFlag, setOpenExpertProfile] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        lastRecord: false
    });
    const [selectedExpert, setSelectedExpert] = useState();
    const [selectedCall, setSelectedCall] = useState();
    const sortByValues = [
        { key: '1', text: 'Newest Profile', value: 'NewestProfiles' },
        { key: '2', text: 'BCG Preferred Partner', value: 'BCGPreferredPartners' },
        { key: '3', text: 'Standard Rates', value: 'StandardRates' },
        { key: '4', text: 'Lowest Price', value: 'LowestPrice' },
    ];
    const [selectedValue, setSelectedValue] = useState(sortByValues[0]?.value);
    const [openRescheduleCall, setOpenRescheduleCall] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        isFollowup: false,
        lastRecord: false
    });
    const [attendees, setAttendees] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (openExpertProfileFlag.open) {
            setSelectedExpert(data[openExpertProfileFlag.selectedIndex]?.expertProfile);
            setSelectedCall(data[openExpertProfileFlag.selectedIndex]);
            setOpenExpertProfile({
                ...openExpertProfileFlag,
                selectedRecord: data[openExpertProfileFlag.selectedIndex]?.expertProfile
            });
        }
    }, [data]);

    const openExpertProfile = (record, index) => {
        if (record?.expertProfile?.duplicate) {
            dispatch(getDuplicateExpAPIData(interviewId, record?.expertProfile?.expertName));
        }
        setOpenExpertProfile({
            open: true,
            selectedIndex: index,
            selectedRecord: record?.expertProfile,
            lastRecord: (index + 1) == data.length
        });
        setSelectedExpert(record?.expertProfile);
        setSelectedCall(record);
        let obj = [];
        if (record?.attendees?.length > 0) {
            record?.attendees?.map((item, index) => {
                obj?.push({ hrId: item?.attendeeHrId });
            })

        } else {
            caseTeamMembers?.map((item, index) => {
                obj?.push({ hrId: item?.hrId });
            });
        }
        setAttendees(obj);
    }

    const handleExpertProfileClose = () => {
        setOpenExpertProfile({
            open: false,
            selectedIndex: -1,
            selectedRecord: null,
            lastRecord: false
        });
        dispatchGridCall(filters['expertName'], filters, 0, 10, undefined);
    }

    const dispatchGridCall = (expertName, filtersData, pageNo, pageSize, selectedFilterStatus, selectedTagsStatus) => {
        const buildQueryData = (filtersData, additionalFields = {}) => ({
            interviewRequestId: interviewId,
            expertName: expertName || null,
            expertTypes: filtersData?.['expertTypes']?.length ? filtersData['expertTypes'] : null,
            relevantCompanies: filtersData?.['companies']?.length ? filtersData['companies'] : null,
            countries: filtersData?.['countries']?.length ? filtersData['countries'] : null,
            networkProviders: filtersData?.['networkProviders']?.length ? filtersData['networkProviders'] : null,
            costs: filtersData?.['cost']?.length ? filtersData['cost'] : null,
            ...additionalFields,
        });

        const profileQueryData = buildQueryData(filtersData, {
            profileStatus: selectedFilterStatus,
            tags: selectedTagsStatus ? [selectedTagsStatus] : [],
        });

        const templateQueryData = buildQueryData(filters, {
            requestId: interviewId,
            tags: getSelectedTagsStatus,
        });

        dispatch(getExpReceivedAPIData(profileQueryData, pageNo, pageSize, selectedValue));
        dispatch(getCallsAPIData(templateQueryData, 0, sortState ? 'Newest' : 'Oldest'));

        setTimeout(() => dispatch(getExpRecAPIData(interviewId)), 0);
    }

    const handleCarouselActionClicked = (action, selectionIndex) => {
        let newSelectedIndex = selectionIndex;
        if (data?.length > 0) {
            if (action == 'next') {
                newSelectedIndex = (selectionIndex + 1);
            } else {
                if (selectionIndex > 0) {
                    newSelectedIndex = (selectionIndex - 1);
                }
            }

            onExpertNameClicked(data[newSelectedIndex]);
            setOpenExpertProfile({
                open: true,
                selectedIndex: newSelectedIndex,
                selectedRecord: data[newSelectedIndex]?.expertProfile,
                lastRecord: (newSelectedIndex + 1) == data?.length
            });
        }
    }
    const onExpertNameClicked = (item) => {
        setSelectedExpert(item?.expertProfile);
        setSelectedCall(item);
        let obj = [];
        if (item?.attendees?.length > 0) {
            item?.attendees?.map((item, index) => {
                obj?.push({ hrId: item?.attendeeHrId });
            })

        } else {
            caseTeamMembers?.map((item, index) => {
                obj?.push({ hrId: item?.hrId });
            });
        }
        setAttendees(obj);
    }

    const handlerRequestCallClick = (selection) => {
        let selectedRecord = [];
        if (selection) {
            selectedRecord = data?.filter((item) => item.id == selection);
            if (selectedRecord?.length > 0) {
                setSelectedExpert(selectedRecord[0]);
            }
        }
        openCallScheduler();
    }

    const openCallScheduler = () => {
        rescheduleCallHandler(selectedCall);
        setOpenScheduleCall(true);
    }

    const rescheduleCallHandler = (callsDetails, isReschedule) => {
        const isExpertSlotSelection = callsDetails.selectedSlots.length > 0 ? callsDetails.selectedSlots[0].isExpertSlot : false;

        const rescheduleData = {
            expertName: callsDetails.expertProfile.expertName,
            projectId: callsDetails.projectId,
            duration: callsDetails.duration,
            callId: callsDetails.id,
            id: callsDetails.expertProfile.id,
            selectedSlots: { ...callsDetails.callManagementScheduledSlot, isExpertSlot: isExpertSlotSelection },
            profileId: callsDetails.expertProfile.profileId,
            networkProvider: callsDetails.expertProfile.networkProvider,
            availableSlots: callsDetails.expertProfile.availableSlots,
            status: callsDetails.callStatus
        };
        setOpenRescheduleCall({
            open: true,
            callData: rescheduleData,
            isReschedule: isReschedule,
            isFollowup: callsDetails?.followUpCall,
            requestId: callsDetails.requestId
        });
    }

    const handleReschedulePopupClose = (flag) => {
        setOpenRescheduleCall({
            open: false,
            callData: null
        });
        refreshCallsGrid(flag);
    }

    const handleSchedulePopupClose = () => {
        setOpenScheduleCall(false);
    }
    const openDuplicateProfiles = (selected) => {
        handleExpertProfileClose();
        if (selected) {
            setOpenComparePopup({
                isOpen: true,
                type: 'duplicate',
                selection: selected
            });
        }
    }
    const refreshProfiles = () => {
        const size = (page + 1) * 10;
        dispatchGridCall(filters['expertName'], filters, 0, size, undefined);
    }

    const getUserTimeZone = async () => {
        try {
            const hrid = await getUserId();
            const resp = await getUserProfilePictures([hrid]);

            if (resp?.length > 0 && resp[0]?.hostOfficeLocation?.timezone) {
                setUserTimezone(resp[0].hostOfficeLocation.timezone);
            }
        } catch (error) {
            setUserTimezone('Error fetching timezone');
        }
    };

    useEffect(() => {
        getUserTimeZone();
    }, []);

    useEffect(() => {
        dispatch(getCallsFiltersAPIData(interviewId));
    }, []);

    useEffect(() => {
        setExpertTypesFilters(callsFilters?.expertTypes);
        setRelevantCompaniesFilters(callsFilters?.companies);
        setRelevantCompaniesInitialFilters(callsFilters?.companies);
        setNetworkProvidersFilters(callsFilters?.networkProviders);
        setStatusFilters(callsFilters?.status);
        setFollowUpCalls(callsFilters?.followUpCalls);
    }, [callsFilters]);

    useEffect(() => {
        setTotal(callsSelectorData?.totalCalls);
        let templateResp = callsSelectorData?.callDetails;
        if (isInitialMount.current) {
            // Skip the effect on initial mount
            isInitialMount.current = false;
            templateResp = [];
            return;
        }
        let newData = Array.isArray(data) ? [...data] : [];

        if (!hideCallRec) {
            if (callsSelectorData?.lastPage && callsSelectorData?.firstPage) {
                setData(callsSelectorData?.callDetails);
            }
            else if (!callsSelectorData?.lastPage) {
                if (data?.length > 0 && !callsSelectorData?.firstPage) {
                    for (let i = 0; i < templateResp?.length; i++) {
                        newData?.push(templateResp[i]);
                    }
                    setData([...newData]);
                } else {
                    setData(callsSelectorData?.callDetails);
                }
            } else {
                for (let i = 0; i < templateResp?.length; i++) {
                    newData?.push(templateResp[i]);
                }
                setData([...newData]);
            }
            setLastPage(callsSelectorData?.lastPage);
            if (callsSelectorData?.callDetails?.length === 0) {
                setHasMore(false);
            }
        }
    }, [callsSelectorData, sortState]);

    const handleExpandedState = () => {
        setExpandedData(null);
        setPage(0);
    }

    const handleDateDisplay = (item) => {
        let startDate = null;
        if (item?.callStatus === 'Completed' && item?.callManagementInterviewedSlot) {
            startDate = new Date(item?.callManagementInterviewedSlot?.startDateTime);
        }
        else if (item?.callStatus === 'Cancelled' && item?.callManagementScheduledSlot) {
            startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
        }
        else if (item?.callStatus === 'Upcoming' && item?.callManagementScheduledSlot) {
            startDate = new Date(item?.callManagementScheduledSlot?.startDateTime);
        }

        if (startDate) {
            const formattedStartDate = formatDate(startDate.toLocaleDateString());
            return formattedStartDate
        } else {
            return 'NA'
        }
    }

    const handleTimeDisplay = (item) => {
        const options = {
            timeZone: userTimezone,
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        };

        const getTimeDisplay = (startDateTime, endDateTime) => {
            const startDate = new Date(startDateTime);
            const endDate = new Date(endDateTime);

            const localStartDateTime = startDate?.toLocaleTimeString('en-US', options);
            const localEndDateTime = endDate?.toLocaleTimeString('en-US', options);

            const { diffHours, remainingMinutes } = calculateTimeDifference(localStartDateTime, localEndDateTime);
            const timeDiff = `${diffHours} Hr${diffHours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} Min` : ''}`;

            return `${localStartDateTime} - ${localEndDateTime} ${userTimezone} (${timeDiff})`;
        };

        if (item?.callStatus === 'Completed' && item?.callManagementInterviewedSlot) {
            return getTimeDisplay(item?.callManagementInterviewedSlot?.startDateTime, item?.callManagementInterviewedSlot?.endDateTime);
        } else if (item?.callStatus === 'Cancelled' && item?.callManagementScheduledSlot) {
            return getTimeDisplay(item?.callManagementScheduledSlot?.startDateTime, item?.callManagementScheduledSlot?.endDateTime);
        } else if (item?.callStatus === 'Upcoming' && item?.callManagementScheduledSlot) {
            return getTimeDisplay(item?.callManagementScheduledSlot?.startDateTime, item?.callManagementScheduledSlot?.endDateTime);
        } else {
            return 'NA';
        }
    };


    useEffect(() => {
        const isClickInsideDropdown = (event) => {
            for (const key in dropdownRefs) {
                if (dropdownRefs[key].current && dropdownRefs[key].current.contains(event.target)) {
                    return true;
                }
            }
            return false;
        };

        const shouldCloseDropdown = (event) => {
            return (
                !isClickInsideDropdown(event) ||
                event.target.classList.contains('filtercross--icon') ||
                event.target.classList.contains('header--text') ||
                event.target.classList.contains('header--divText')
            );
        };

        const handleClickOutside = (event) => {
            if (shouldCloseDropdown(event)) {
                setClickOutside(true);
                setDropdownOpen({
                    expertName: false,
                    expertTypes: false,
                    relevantCompanies: false,
                    networkProviders: false,
                    status: false,
                });
                setShowCrossIcon({
                    expertName: false,
                    expertTypes: false,
                    relevantCompanies: false,
                    networkProviders: false,
                    status: false,
                });
            } else {
                setClickOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [crossIconClick]);

    const handleRowClick = (item) => {
        setExpandedDataResp(item);
        setExpandedData((prevId) => (prevId === item?.id ? null : item?.id));
    }

    const handleStatus = (item) => {
        if (item === 'Completed') {
            return 'completed--status'
        }
        else if (item === 'Upcoming') {
            return 'upcoming--status'
        }
        else if (item === 'Requested') {
            return 'requested--status'
        }
        else if (item === 'Cancelled') {
            return 'cancelled--status'
        }
    }

    const handleCrossIconClick = (type) => {
        setDropdownOpen({
            expertName: false,
            expertTypes: false,
            relevantCompanies: false,
            networkProviders: false,
            status: false,
        });
        setCrossIconClick(true);
        toggleCrossIcon(type, false);
    };

    const loadMoreData = () => {
        if (lastPage === false) {
            setPage(prevPage => prevPage + 1);
            fetchData(page + 1);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [hideCallRec]);

    const fetchData = async (page) => {
        if (!hideCallRec) {
            let templateQueryData = {
                requestId: interviewId,
                expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                tags: getSelectedTagsStatus,
            }
            dispatch(getCallsAPIData(templateQueryData, page, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        }
    };

    const handleSort = (flag) => {
        if (loading) {
            return
        }
        setData([]);
        setPage(0);
        setLastPage(true);
        setSelectedTagsStatus([]);
        let templateQueryData = {
            requestId: interviewId,
            expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            tags: null,
        }
        dispatch(getCallsAPIData(templateQueryData, page, flag === true ? 'Newest' : 'Oldest'));
        setSortState(flag);
    };

    const handleClearFilter = (column) => {
        setData([]);
        setSelectedTagsStatus([]);
        if (column) {
            setFilters({ ...filters, [column]: [] });
            toggleCrossIcon(column, false);
        } else {
            setFilters({ ...filters });
        }
        setSelectedTagsStatus([]);
        setDropdownOpen({
            expertName: false,
            expertTypes: false,
            relevantCompanies: false,
            networkProviders: false,
            status: false,
        });
        let templateQueryData = {
            requestId: interviewId,
            expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
            status: filters['status']?.length > 0 ? filters['status'] : null,
            tags: null
        }
        if (column === 'expertName') {
            templateQueryData.expertName = '';
            setExpertNameSearchTerm('');
        }
        else if (column === 'expertTypes') {
            templateQueryData.expertTypes = null;
        }
        else if (column === 'networkProviders') {
            templateQueryData.networkProviders = null;
        }
        else if (column === 'relevantCompanies') {
            templateQueryData.relevantCompanies = null;
        }
        else if (column === 'status') {
            templateQueryData.status = null;
        }
        setPage(0);
        dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        toggleCrossIcon(column, false);
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }

    const searchExpertNameHandler = (expertName) => {
        if (expertName?.length > 2) {
            if (loading) {
                return
            }
            setData([]);
            setPage(0);
            setSelectedTagsStatus([]);
            setFilters({ ...filters, ['expertName']: expertName });
            let templateQueryData = {
                requestId: interviewId,
                expertName: expertName,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                tags: null
            }
            dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        }
    }

    const handleSearchChange = (event, type) => {
        event.stopPropagation();
        const searchTerm = event?.target?.value?.toLowerCase();
        if (type === 'relevantCompanies') {
            setRelevantCompanySearchTerm(event?.target?.value);
            let companiesFiltersTempResult = relevantCompaniesInitialFilters?.filter((item) => {
                return item?.name?.toLowerCase().includes(searchTerm);
            });
            setRelevantCompaniesFilters(companiesFiltersTempResult);
        }
        if (type === 'expertName') {
            setRelevantCompanySearchTerm('');
            setExpertNameSearchTerm(event?.target?.value);
        }
    };

    const uniqueValuesForColumn = (column) => {
        switch (column) {
            case 'companies':
                return relevantCompaniesFilters;
            case 'status':
                return statusFilters;
            case 'expertTypes':
                return expertTypesFilters;
            case 'networkProviders':
                return networkProvidersFilters;
        }
    };

    const handleChangeFilter = (column, value) => {
        setPage(0)
        setData([]);
        setExpandedData(null);
        setSelectedTagsStatus([]);
        const newFilters = filters[column]?.includes(value)
            ? filters[column].filter(item => item !== value)
            : [...filters[column], value];

        setFilters({ ...filters, [column]: newFilters });
        let tempFilters = { ...filters, [column]: newFilters }

        let templateQueryData = {
            requestId: interviewId,
            expertName: tempFilters['expertName']?.length > 0 ? tempFilters['expertName'] : null,
            relevantCompanies: tempFilters['relevantCompanies']?.length > 0 ? tempFilters['relevantCompanies'] : null,
            expertTypes: tempFilters['expertTypes']?.length > 0 ? tempFilters['expertTypes'] : null,
            networkProviders: tempFilters['networkProviders']?.length > 0 ? tempFilters['networkProviders'] : null,
            status: tempFilters['status']?.length > 0 ? tempFilters['status'] : null,
            tags: null
        }
        dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
    }

    const handleSelectAll = (selectAllParam, selFilter) => {
        if (loading) {
            return;
        }
        setSelectedTagsStatus([]);
        setPage(0);

        const updateFiltersAndDispatch = (filterKey, selFilter) => {
            const temparr = filters[filterKey].length === selFilter.length ? [] : selFilter.map(item => item.name);
            setFilters({ ...filters, [filterKey]: temparr });

            const templateQueryData = {
                requestId: interviewId,
                expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
                relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
                expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
                networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
                status: filters['status']?.length > 0 ? filters['status'] : null,
                tags: null,
            };

            templateQueryData[filterKey] = temparr;
            dispatch(getCallsAPIData(templateQueryData, 0, sortState !== null && (sortState === true ? 'Newest' : 'Oldest')));
        };

        switch (selectAllParam) {
            case 'expertTypesAll':
                updateFiltersAndDispatch('expertTypes', selFilter);
                break;
            case 'networkProvidersAll':
                updateFiltersAndDispatch('networkProviders', selFilter);
                break;
            case 'relevantCompaniesAll':
                updateFiltersAndDispatch('relevantCompanies', selFilter);
                break;
            case 'statusAll':
                updateFiltersAndDispatch('status', selFilter);
                break;
            default:
                break;
        }
    };


    const toggleCrossIcon = (column, flag) => {
        if (flag) {
            setShowCrossIcon({ ...showCrossIcon, [column]: flag });
        }
        else {
            setShowCrossIcon({ ...showCrossIcon, [column]: false });
        }
    };

    const buildTemplateQueryData = (tagName) => {
        return {
            requestId: interviewId,
            expertName: filters['expertName']?.length > 0 ? filters['expertName'] : null,
            expertTypes: filters['expertTypes']?.length > 0 ? filters['expertTypes'] : null,
            relevantCompanies: filters['relevantCompanies']?.length > 0 ? filters['relevantCompanies'] : null,
            status: filters['status']?.length > 0 ? filters['status'] : [],
            networkProviders: filters['networkProviders']?.length > 0 ? filters['networkProviders'] : null,
            tags: tagName ? [tagName] : null
        };
    };

    const handleTagsFilterSelection = (event) => {
        if (getFollowUpCalls !== 0) {
            const tagName = event.target.getAttribute('data-tag');
            setSelectedTagsStatus([tagName]);
            setData([]);
            setPage(0);

            const isSameTag = tagName === getSelectedTagsStatus[0];
            const templateQueryData = buildTemplateQueryData(isSameTag ? null : tagName);

            if (isSameTag) {
                setSelectedTagsStatus([]);
            }

            dispatch(getCallsAPIData(templateQueryData, 0, (sortState !== null && (sortState === true ? 'Newest' : 'Oldest'))));
        }
    };

    const refreshCallsGrid = (flag) => {
        if (flag) {
            handleClearFilter('');
        }
    }

    const getFollowUpClassName = getFollowUpCalls
        ? `top-filter-name ${getSelectedTagsStatus?.includes('followUp') ? 'selected' : ''}`
        : `top-filter-name noCursor`;


    const getSortIcon = () => {
        if (sortState === null) {
            return SortIcon;
        } else if (sortState === true) {
            return SortAsc;
        } else {
            return SortDesc;
        }
    };

    return (
        <>
            <div className="requestCalls__wrap">
                <div className='requestCalls__head'>
                    <div className="grid-header-count">
                        <div className='count--badge'>{total || '0'}</div>
                        Calls
                    </div>
                    <div className='download__callWrap'>
                        <Image src={DownloadIcon} className='download--icon' alt="DownloadIcon"
                        />
                        Download Call Data
                    </div>
                </div>
                {
                    noRecFound === false &&
                    <div className="requestCalls__top-filters">
                        <div className={getFollowUpClassName} data-tag="followUp" onClick={handleTagsFilterSelection}>
                            Follow Up Calls &nbsp;{getFollowUpCalls}
                        </div>
                    </div>
                }
                {
                    <>
                        <Table sortable celled className='calls--table'>
                            <Table.Header className='completedcalls__header'>
                                <Table.Row>
                                    <Table.HeaderCell className='exp-header--title width-action-col'>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['expertName']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Expert Name
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters && filters['expertName']?.length > 0 &&
                                                    <div className='filter--count'><Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertName')} /></div>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertName']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: true,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: true,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertName'] === true ? CrossIcon : SearchIcon} alt="icon" className={dropdownOpen['expertName'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertName');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={'calls--menuLessData expertName--menu'}>
                                                        <Dropdown.Item key={0}>
                                                            <div className="dropdown--search">
                                                                <Dropdown.SearchInput type="string"
                                                                    value={expertNameSearchTerm}
                                                                    onChange={(e) => handleSearchChange(e, 'expertName')}
                                                                    onKeyUp={(e) => {
                                                                        handleKeyUp(e);
                                                                    }
                                                                    }
                                                                    placeholder="Search By Experts Name"
                                                                    className="search--input paddingnew" />
                                                                <Image src={GreenSearchIcon} alt="search icon" className={`search--icon greensearch--icon ${expertNameSearchTerm.length < 3 && "disable-icon"}`}
                                                                    onClick={() => searchExpertNameHandler(expertNameSearchTerm)} />
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div'>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Relevant Position
                                                </span>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['relevantCompanies']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Relevant Company
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.relevantCompanies?.length > 0 &&
                                                    <span className='filter--count'>{filters?.relevantCompanies?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('relevantCompanies')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['relevantCompanies']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: true,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: true,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['relevantCompanies'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['relevantCompanies'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('relevantCompanies');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={relevantCompaniesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        <div className="dropdown--search">
                                                            <Dropdown.SearchInput type="string"
                                                                value={relevantCompanySearchTerm}
                                                                onChange={(e) => handleSearchChange(e, 'relevantCompanies')}
                                                                onKeyUp={(e) => {
                                                                    handleKeyUp(e);
                                                                }
                                                                }
                                                                placeholder="Search Relevant Company"
                                                                className="search--input" />
                                                            <Image src={SearchIcon} alt="search icon" className="search--icon" />
                                                        </div>
                                                        {
                                                            relevantCompaniesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('relevantCompaniesAll', relevantCompaniesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['relevantCompanies']?.length === relevantCompaniesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['relevantCompanies']?.length === relevantCompaniesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {relevantCompaniesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value}
                                                                                onClick={() => handleChangeFilter('relevantCompanies', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['relevantCompanies']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['relevantCompanies']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('relevantCompanies')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['expertTypes']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Expert Type / Modules
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.expertTypes?.length > 0 &&
                                                    <span className='filter--count'>{filters?.expertTypes?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertTypes')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertTypes']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: true,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: true,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertTypes'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['expertTypes'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertTypes');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={expertTypesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            expertTypesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('expertTypesAll', expertTypesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['expertTypes']?.length === expertTypesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['expertTypes']?.length === expertTypesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {expertTypesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value}
                                                                                onClick={() => handleChangeFilter('expertTypes', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['expertTypes']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['expertTypes']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('expertTypes')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['networkProviders']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Network
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.networkProviders?.length > 0 &&
                                                    <span className='filter--count'>{filters?.networkProviders?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('networkProviders')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['networkProviders']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: true,
                                                                status: false,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: true,
                                                                status: false,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['networkProviders'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['networkProviders'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('networkProviders');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={networkProvidersFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            networkProvidersFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('networkProvidersAll', networkProvidersFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['networkProviders']?.length === networkProvidersFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['networkProviders']?.length === networkProvidersFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {networkProvidersFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value}
                                                                                onClick={() => handleChangeFilter('networkProviders', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['networkProviders']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['networkProviders']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('networkProviders')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border dateHead'>
                                        <div className='header--div' ref={dropdownRefs['startDate']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Date
                                                </span>
                                            </div>
                                            <Image src={getSortIcon()} alt="icon" className='filter--icon' onClick={() => handleSort(!sortState)} />
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['startTime']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Time
                                                </span>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title statusHead'>
                                        <div className='header--div' ref={dropdownRefs['status']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Status
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.status?.length > 0 &&
                                                    <span className='filter--count'>{filters?.status?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('status')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['status']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: true,
                                                            });
                                                            setShowCrossIcon({
                                                                expertName: false,
                                                                expertTypes: false,
                                                                relevantCompanies: false,
                                                                networkProviders: false,
                                                                status: true,
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['status'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['status'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('status');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={statusFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            statusFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('statusAll', statusFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['status']?.length === statusFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['status']?.length === statusFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {statusFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value}
                                                                                onClick={() => handleChangeFilter('status', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['status']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['status']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>{value?.name}</div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('status')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <div id='scrollable-container' className={data?.length < 5 && expandedData === null ? 'padding-right-2' : 'padding-right-9'}>
                            <InfiniteScroll
                                dataLength={data?.length || 0}
                                hasMore={hasMore}
                                scrollableTarget="scrollable-container"
                                next={loadMoreData}
                                className='commTemplates__infiniteScroll'
                            >
                                <Table sortable celled className='calls--table'>
                                    <Table.Body className='completedcalls__body'>
                                        {loading ? (
                                            <div className='loading--center'>
                                                <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                                            </div>
                                        ) : noRecFound ? (
                                            <div className='noRecFound'>
                                                Currently, there are no calls scheduled yet.
                                            </div>
                                        ) : (
                                            data?.map((item, index) => (
                                                <React.Fragment key={'rc' + index}>
                                                    <Table.Row
                                                        className={item?.profileStatus?.toLowerCase() === 'new' && !item?.shortlisted ?
                                                            "exp--row rowShortlistedClr" : "exp--row rowDefaultClr"}
                                                    >
                                                        <Table.Cell className='user--data table--cell expand--data'>
                                                            <Image src={expandedData === item?.id ? CollapseIcon : ExpandIcon} alt="Expand/Collapse"
                                                                onClick={() => handleRowClick(item)} />
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell expert--name' data-tooltip-id={item?.expertProfile?.expertName} data-tooltip-content={item?.expertProfile?.expertName} onClick={() => openExpertProfile(item, index)}>
                                                            {item?.expertProfile?.expertName?.length > 25 ? item?.expertProfile?.expertName?.substring(0, 25)?.concat('...') : item?.expertProfile?.expertName}
                                                            {
                                                                item?.expertProfile?.expertName?.length > 25 &&
                                                                <ReactTooltip id={item?.expertProfile?.expertName} place="bottom-start" className="expert--tooltip" />
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell' data-tooltip-id={item?.expertProfile?.relevantExperience?.title} data-tooltip-content={item?.expertProfile?.relevantExperience?.title}>
                                                            {item?.expertProfile?.relevantExperience?.title?.length > 50 ? item?.expertProfile?.relevantExperience?.title?.substring(0, 50)?.concat('...') : item?.expertProfile?.relevantExperience?.title}
                                                            {
                                                                item?.expertProfile?.relevantExperience?.title?.length > 50 &&
                                                                <ReactTooltip id={item?.expertProfile?.relevantExperience?.title} place="bottom-start" className="expert--tooltip" />
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell' data-tooltip-id={item?.expertProfile?.relevantExperience?.employerName} data-tooltip-content={item?.expertProfile?.relevantExperience?.employerName}>
                                                            {item?.expertProfile?.relevantExperience?.employerName?.length > 45 ? item?.expertProfile?.relevantExperience?.employerName?.substring(0, 45)?.concat('...') : item?.expertProfile?.relevantExperience?.employerName}
                                                            {
                                                                item?.expertProfile?.relevantExperience?.employerName?.length > 45 &&
                                                                <ReactTooltip id={item?.expertProfile?.relevantExperience?.employerName} place="bottom-start" className="expert--tooltip" />
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell exp--cell' data-tooltip-id={item?.expertProfile?.expertTypes.join(', ')} data-tooltip-content={item?.expertProfile?.expertTypes.join(', ')}>{Array.isArray(item?.expertProfile?.expertTypes) ? (item?.expertProfile?.expertTypes?.length > 45 ? (item?.expertProfile?.expertTypes.join(', ')?.substring(0, 45)?.concat('...')) : item?.expertProfile?.expertTypes.join(', ')) : 'NA'}
                                                            {
                                                                item?.expertProfile?.expertTypes?.join(', ')?.length > 45 &&
                                                                <ReactTooltip id={item?.expertProfile?.expertTypes.join(', ')} place="bottom-start" className="expert--tooltip" />
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell'>
                                                            {item?.expertProfile?.networkProvider}
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell date2'>
                                                            {
                                                                handleDateDisplay(item)
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell'>
                                                            {
                                                                handleTimeDisplay(item)
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell className='user--data table--cell date--cell'>
                                                            <div className={handleStatus(item?.callStatus)}>
                                                                {item?.callStatus}
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>

                                                    {expandedData === item?.id && (
                                                        <Table.Row className='template--div'>
                                                            <Table.Cell colSpan={9} className='template--cellwrap'>
                                                                <div className='template--cell'>
                                                                    <CallsTemplate data={expandedDataResp} isClosedRequest={isClosedRequest} handleExpandedState={handleExpandedState} caseTeamMembers={caseTeamMembers} refreshCallsGrid={(flag) => refreshCallsGrid(flag)} />
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </Table.Body>

                                </Table>
                            </InfiniteScroll>

                        </div>
                    </>
                }
            </div>
            <>
                {openRescheduleCall?.open && <ScheduleCall
                    open={openRescheduleCall.open}
                    data={openRescheduleCall.callData}
                    caseTeamMembers={attendees}
                    requestId={openRescheduleCall.requestId}
                    isRescheduleWindowFlag={openRescheduleCall.isReschedule}
                    isFollowUpCallFlag={openRescheduleCall.isFollowup}
                    closePopupHandler={(flag) => handleReschedulePopupClose(flag)}
                />}
                <>
                    {openExpertProfileFlag?.open && selectedExpert && <ExpertProfile
                        open={openExpertProfileFlag.open}
                        isClosedRequest={isClosedRequest}
                        selectedIndex={openExpertProfileFlag.selectedIndex}
                        selectedRecord={openExpertProfileFlag.selectedRecord}
                        lastRecord={openExpertProfileFlag.lastRecord}
                        data={selectedExpert}
                        allData={data}
                        duplicateProfiles={openExpertProfileFlag.duplicateProfiles}
                        refreshProfileHandler={refreshProfiles}
                        closePopupHandler={() => handleExpertProfileClose()}
                        carouselActionClicked={(action, selection) => handleCarouselActionClicked(action, selection)}
                        openScheduler={() => handlerRequestCallClick(null)}
                        handleDuplicateProfilesClick={(selected) => openDuplicateProfiles(selected)}
                    />}
                </>
            </>
        </>
    );
};