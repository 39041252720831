import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Popup } from 'semantic-ui-react';
import { Table, Dropdown, Checkbox } from 'semantic-ui-react';
import Image from "components/shared/Image";
import DownloadIcon from "assets/images/download-icon.svg";
import GreenCrossIcon from 'assets/images/close-popup.svg';
import MinusIcon from "assets/images/minus-outline.svg";
import CompareIcon from 'assets/images/compare.svg';
import { ReactComponent as GreenIcon } from 'assets/images/down_arrow.svg'
import StarOutlineIcon from "assets/images/star-outline.svg";
import FilterIcon from "assets/images/filter_icon.svg";
import SearchIcon from "assets/images/search-icon.svg";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import CrossIcon from "assets/images/filter-close.svg";
import BadgeIcon from "assets/images/badge.svg";
import StarIcon from "assets/images/star.svg";
import DownArrow from "assets/images/down_arrow.svg";
import UpArrow from "assets/images/up_arrow.svg";
import WhiteCrossIcon from "assets/images/white-cross.svg";
import CommentIcon from "assets/images/comment-icon.svg";
import { selectors as downloadExpertsSelector } from 'redux/reducers/DownloadExperts/DownloadExperts_reducer';
import { downloadExpertsAPIData } from 'redux/actions/DownloadExperts/DownloadExperts.actions';
import { getExpReceivedAPIData } from 'redux/actions/expertReceived/expertReceived.actions';
import { getExpRecAPIData } from 'redux/actions/expRecFilters/expRecFilters.actions';
import { selectors as expReceivedSelectors } from 'redux/reducers/expertReceived/expertReceived_reducer';
import { selectors as expRecFiltersSelectors } from 'redux/reducers/expRecFilters/expRecFilters_reducer';
import { selectors as duplicateExpProfilesSelectors } from 'redux/reducers/duplicateExperts/duplicateExperts_reducer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import queryString from 'query-string';
import './ExpertsReceived.scss';
import { ScheduleCall } from "components/ScheduleCall";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { ExpertProfile } from "components/ExpertProfile";
import { setExpertProfileActionsAPIData } from "redux/actions/expertProfileActions/expertProfileActions.actions";
import { selectors as profileActionSelector } from "redux/reducers/expertProfileActions/expertProfileActions_reducer";
import { CompareProfiles } from "components/CompareProfiles";
import { getDuplicateExpAPIData } from "redux/actions/DuplicateExpertProfiles/duplicateExpertProfiles.actions";

import { CommentsPane } from "components/CommentsPane/CommentsPane";
import { ActionAlertWindow } from "components/shared/ActionAlertWindow";

import * as XLSX from 'xlsx';
import { clearDownloadExpertsAPIData } from 'redux/actions/DownloadExperts/DownloadExperts.actions';

export const ExpertsReceived = ({ interviewId, hideExpRec, caseTeamMembers, isClosedRequest }) => {
    const dispatch = useDispatch();
    const statusPopupRef = useRef(null);
    const filterRef = useRef(null);
    const generateRandomKey = Math.random().toString(36).substring(2, 9);
    const profilesActionStatus = useSelector(profileActionSelector.getSaveProfileActionsResults);
    const profileActionError = useSelector(profileActionSelector.getErrorMessage);
    const downloadExpertsResults = useSelector(downloadExpertsSelector?.getDownloadExpertsResults);
    const loadingData = useSelector(downloadExpertsSelector?.getLoading);

    const sortByValues = [
        { key: '1', text: 'Newest Profile', value: 'NewestProfiles' },
        { key: '2', text: 'BCG Preferred Partner', value: 'BCGPreferredPartners' },
        { key: '3', text: 'Standard Rates', value: 'StandardRates' },
        { key: '4', text: 'Lowest Price', value: 'LowestPrice' },
    ];
    const [selectedValue, setSelectedValue] = useState(sortByValues[0]?.value);
    const [statusData, setStatusData] = useState([]);
    const [commentsPanelDetails, setCommentsPanelDetails] = useState({
        open: false,
        profileId: -1,
        savedComments: []
    });
    const [commentExpName, setCommentExpName] = useState('');
    const [visible, setVisible] = useState(false);
    const [declinedReason, setDeclinedReason] = useState('');
    const duplicateProfilesData = useSelector(duplicateExpProfilesSelectors?.getDuplicateExpData);
    const expReceivedData = useSelector(expReceivedSelectors?.getExpReceivedData);
    const expRecFiltersData = useSelector(expRecFiltersSelectors?.getExpRecFiltersResults);
    const noRecFound = useSelector(expReceivedSelectors?.getNoRecordFound);
    const loading = useSelector(expReceivedSelectors?.getLoading);

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [getShowDownload, setShowDownload] = useState({ open: false });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isFilterStatusPopupOpen, setFilterStatusPopupOpen] = useState(false);
    const [selectedFilterStatus, setSelectedFilterStatus] = useState([]);
    const [selectedTagsStatus, setSelectedTagsStatus] = useState([]);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const [showCrossIcon, setShowCrossIcon] = useState({
        'expertTypes': false,
        'companies': false,
        'countries': false,
        'networkProviders': false,
        'cost': false,
        'expertName': false
    });
    const [dropdownOpen, setDropdownOpen] = useState({
        networkProviders: false,
        expertTypes: false,
        cost: false,
        companies: false,
        countries: false,
        expertName: false
    });
    const [filters, setFilters] = useState({
        'expertName': '',
        'expertTypes': [],
        'companies': [],
        'countries': [],
        'networkProviders': [],
        'cost': []
    });
    const [noSearchRecFound, setNoSearchRecFound] = useState(true);
    const [countrySearchTerm, setCountrySearchTerm] = useState('');
    const [companySearchTerm, setCompanySearchTerm] = useState('');
    const [expertNameSearchTerm, setExpertNameSearchTerm] = useState('');
    const dropdownRefs = {
        networkProviders: useRef(null),
        expertTypes: useRef(null),
        cost: useRef(null),
        companies: useRef(null),
        countries: useRef(null),
        expertName: useRef(null)
    };
    const [clickOutside, setClickOutside] = useState(false);
    const [crossIconClick, setCrossIconClick] = useState(false);
    const [expertTypesFilters, setExpertTypesFilters] = useState([]);
    const [companiesFilters, setCompaniesFilters] = useState([]);
    const [companiesInitialFilters, setCompaniesInitialFilters] = useState([]);
    const [countriesFilters, setCountriesFilters] = useState([]);
    const [countriesInitialFilters, setCountriesInitialFilters] = useState([]);
    const [networkProvidersFilters, setNetworkProvidersFilters] = useState([]);
    const [costFilter, setCostFilter] = useState([]);
    const [total, setTotal] = useState(0);
    const [openScheduleCall, setOpenScheduleCall] = useState(false);
    const [openExpertProfileFlag, setOpenExpertProfile] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        lastRecord: false
    });
    const [selectedExpert, setSelectedExpert] = useState();
    const [prevDataFlag, setPrevDataFlag] = useState(false);
    const [multipleActionsMadeFlag, setMultipleActionsMadeFlag] = useState(true);
    const [commentSavedFlag, setCommentSavedFlag] = useState(true);
    const [openComparePopup, setOpenComparePopup] = useState({
        isOpen: false,
        type: '',
        selection: []
    });
    const [networkRecommended, setNetworkRecommended] = useState(0);
    const [bcgPreferredNetwork, setBcgPreferredNetwork] = useState(0);
    const [duplicate, setDuplicate] = useState(0);
    const [shortlisted, setShortlisted] = useState(0);
    const isInitialMount = useRef(true);

    useEffect(() => {
        setNoSearchRecFound(!prevDataFlag ? noRecFound : false);
    }, [noRecFound]);

    useEffect(() => {
        if (profileActionError) {
            toast.error(profileActionError);
        }
    }, [profileActionError]);

    useEffect(() => {
        if (openExpertProfileFlag.open) {
            setOpenExpertProfile({ ...openExpertProfileFlag, duplicateProfiles: duplicateProfilesData });
        }
        if (openComparePopup.isOpen && openComparePopup.type == 'duplicate') {
            setOpenComparePopup({ ...openComparePopup, selection: duplicateProfilesData });
        }
    }, [duplicateProfilesData]);

    useEffect(() => {
        setShowDownload({ ...getShowDownload, loading: loadingData });
    }, [loadingData]);

    useEffect(() => {
        const excelData = [], dataToExport = downloadExpertsResults;
        if (dataToExport?.length > 0) {
            //kirti: need to make common function from download functionality
            dataToExport?.map((item, index) => {
                const screeningResponses = item?.screeningQuestions?.map(sq =>
                    `Question: ${sq.question} Answer: ${sq.answer}`
                ).join('\n');
                excelData?.push({
                    'Expert Name': item?.expertName,
                    'Expert Type/Module': item?.expertTypes?.join(', '),
                    'Relevant Company': item?.relevantExperience?.employerName,
                    'Relevant Position': item?.relevantExperience?.title,
                    'Country': item?.country,
                    'Expert Highlights/Biography': item?.biography,
                    'Screening Responses': screeningResponses,
                    'Network': item?.networkProvider,
                    'Cost/hr': item?.rate?.costPerHour,
                    'Cost/30 min': item?.rate?.costPer30Mins,
                    'Recommended tag': item?.recommended,
                    'Status': item?.profileStatus,
                    'Shortlisted': item?.shortlisted
                });
            });

            const workbook = XLSX.utils.book_new();

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(excelData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Save the workbook to an Excel file
            XLSX.writeFile(workbook, `${interviewId} Expert Profiles.xlsx`);
            dispatch(clearDownloadExpertsAPIData());
            handleClosePopup(true);
        }
    }, [downloadExpertsResults, loadingData]);

    useEffect(() => {
        if (profilesActionStatus?.message && (!multipleActionsMadeFlag || !commentSavedFlag)) {
            toast.success(profilesActionStatus?.message);
            setMultipleActionsMadeFlag(true);
            setCommentSavedFlag(true);
            setSelectedCheckBoxes([]);
            setSelectedTagsStatus([]);
            setExpertNameSearchTerm(filters['expertName']);
            dispatchGridCall(filters['expertName'], filters, page, 10, selectedFilterStatus, undefined);
        }
    }, [profilesActionStatus]);

    useEffect(() => {
        setExpertTypesFilters(expRecFiltersData?.expertTypes);
        setCompaniesFilters(expRecFiltersData?.companies);
        setCompaniesInitialFilters(expRecFiltersData?.companies);
        setCountriesInitialFilters(expRecFiltersData?.countries);
        setCountriesFilters(expRecFiltersData?.countries);
        setNetworkProvidersFilters(expRecFiltersData?.networkProviders);
        setCostFilter(expRecFiltersData?.costs);
        setNetworkRecommended(expRecFiltersData?.networkRecommended);
        setBcgPreferredNetwork(expRecFiltersData?.bcgPreferredNetwork);
        setDuplicate(expRecFiltersData?.duplicate);
        setShortlisted(expRecFiltersData?.shortlisted);
        let arr = [];
        expRecFiltersData?.profileStatus?.map((item) => {
            arr?.push({
                name: item?.name,
                count: item?.count
            })
        });
        setStatusData(arr);
    }, [expRecFiltersData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleClickOutside = (event) => {
            if (statusPopupRef.current && !statusPopupRef.current.contains(event.target) && !filterRef.current.contains(event.target)) {
                setFilterStatusPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (commentsPanelDetails.profileId > -1) {
            const selectedIndex = data.findIndex((i) => i.id == commentsPanelDetails.profileId);
            if (selectedIndex > -1) {
                setCommentsPanelDetails({ ...commentsPanelDetails, savedComments: data[selectedIndex].userComments });
            }
        }
        if (openExpertProfileFlag.open) {
            setSelectedExpert(data[openExpertProfileFlag.selectedIndex]);
            setOpenExpertProfile({
                ...openExpertProfileFlag,
                selectedRecord: data[openExpertProfileFlag.selectedIndex]
            });
        }
        if (openComparePopup.isOpen) {
            if (openComparePopup.type == 'duplicate') {
                const expertName = openComparePopup.selection ? openComparePopup.selection[0].expertName : '';
                if (expertName) {
                    dispatch(getDuplicateExpAPIData(interviewId, expertName));
                }
            }
            else if (selectedCheckBoxes?.length > 0) {
                let selectedProfiles = [];
                selectedCheckBoxes.forEach((selection) => {
                    const record = data.filter(item => item.profileId == selection);
                    if (record) {
                        selectedProfiles.push(record[0]);
                    }
                });
                setOpenComparePopup({
                    ...openComparePopup,
                    selection: selectedProfiles
                });
            }
        }
    }, [data]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            let isClickInside = false;
            for (const key in dropdownRefs) {
                if (dropdownRefs[key].current && dropdownRefs[key].current.contains(event.target)) {
                    isClickInside = true;
                    break;
                }
            }

            if (!isClickInside || event.target.classList.contains('filtercross--icon') || event.target.classList.contains('header--text') || event.target.classList.contains('header--divText')) {
                setClickOutside(true);
                setExpertNameSearchTerm(filters['expertName']);
                setDropdownOpen({
                    networkProviders: false,
                    expertTypes: false,
                    countries: false,
                    cost: false,
                    companies: false,
                    expertName: false
                });
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: false,
                    countries: false,
                    cost: false,
                    companies: false,
                    expertName: false
                });
                setCompanySearchTerm('');
                setCompaniesFilters(expRecFiltersData?.companies);
                setCountriesFilters(expRecFiltersData?.countries);
                setCountrySearchTerm('');
            }
            else {
                setClickOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [crossIconClick]);
    useEffect(() => {
        const templateResp = getTemplateResponse(expReceivedData);

        if (!openComparePopup.isOpen) {
            handleInitialMount();
            setTotal(expReceivedData?.profileCountTotal);
        }

        if (!hideExpRec) {
            const updatedData = getUpdatedData(templateResp, data, expReceivedData);
            setData(updatedData);

            if (!openComparePopup.isOpen) {
                setLastPage(expReceivedData?.lastPage);
                setHasMore(templateResp.length > 0);
            }
        }
    }, [expReceivedData]);

    const getTemplateResponse = (expReceivedData) => {
        return Array.isArray(expReceivedData?.expertProfiles) ? expReceivedData.expertProfiles : [];
    };

    const handleInitialMount = () => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
    };

    const getUpdatedData = (templateResp, currentData, expReceivedData) => {
        if (expReceivedData?.lastPage && expReceivedData?.firstPage) {
            return templateResp;
        } else if (!expReceivedData?.lastPage) {
            return currentData?.length > 0 && !expReceivedData?.firstPage
                ? [...currentData, ...templateResp]
                : templateResp;
        } else {
            return [...currentData, ...templateResp];
        }
    };


    useEffect(() => {
        if (!hideExpRec)
            dispatchGridCall(filters['expertName'], filters, 0, 10, selectedFilterStatus, selectedTagsStatus[0]);
    }, [selectedValue]);

    const handleSortByChange = (event, sortValue) => {
        setSelectedValue(sortValue?.value);
        setPage(0);
    }

    const openCallScheduler = () => {
        setOpenScheduleCall(true);
    }

    const openExpertProfile = (record, index) => {
        if (record.duplicate) {
            dispatch(getDuplicateExpAPIData(interviewId, record.expertName));
        }
        setOpenExpertProfile({
            open: true,
            selectedIndex: index,
            selectedRecord: record,
            lastRecord: (index + 1) == data.length
        });
    }
    const handleSchedulePopupClose = () => {
        setOpenScheduleCall(false);
    }
    const handleExpertProfileClose = () => {
        setOpenExpertProfile({
            open: false,
            selectedIndex: -1,
            selectedRecord: null,
            lastRecord: false
        });
        setSelectedTagsStatus([]);
        dispatchGridCall(filters['expertName'], filters, page, 10, undefined);
    }

    const handleTooltipData = (item) => {
        const screeningQuestions = item?.screeningQuestions || [];
        const formattedData = screeningQuestions.map((qa) => {
            const question = qa?.question || '';
            const answer = qa?.answer || '';

            const truncatedQuestion = `Q: ${question}`;
            const truncatedAnswer = `A: ${answer}`;

            return `${truncatedQuestion}\n${truncatedAnswer}`
        }).join(', ');
        return formattedData;
    };

    const loadMoreData = () => {
        if (lastPage === false) {
            setPage(prevPage => prevPage + 1);
            fetchData(page + 1);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [hideExpRec]);

    const fetchData = async (pageNo) => {
        if (!hideExpRec) {
            dispatchGridCall('', filters, pageNo, 10, selectedFilterStatus, selectedTagsStatus[0]);
        }
    };

    const dispatchGridCall = (expertName, filtersData, pageNo, pageSize, selectedFilterStatus, selectedTagsStatus) => {
        let isGridFiltered = false;
        if (expertName || (filtersData &&
            (filtersData['expertTypes']?.length > 0 ||
                filtersData['companies']?.length > 0 ||
                filtersData['countries']?.length > 0 ||
                filtersData['networkProviders']?.length > 0 ||
                filtersData['cost']?.length > 0))) {
            isGridFiltered = true;
        }
        setPrevDataFlag(isGridFiltered);
        let profileQueryData = {
            interviewRequestId: interviewId,
            expertName: expertName,
            expertTypes: filtersData && filtersData['expertTypes']?.length > 0 ? filtersData['expertTypes'] : null,
            relevantCompanies: filtersData && filtersData['companies']?.length > 0 ? filtersData['companies'] : null,
            countries: filtersData && filtersData['countries']?.length > 0 ? filtersData['countries'] : null,
            networkProviders: filtersData && filtersData['networkProviders']?.length > 0 ? filtersData['networkProviders'] : null,
            costs: filtersData && filtersData['cost']?.length > 0 ? filtersData['cost'] : null,
            profileStatus: selectedFilterStatus,
            tags: (selectedTagsStatus !== undefined) ? [selectedTagsStatus] : []
        };
        dispatch(getExpReceivedAPIData(profileQueryData, pageNo, pageSize, selectedValue));
        setTimeout(() => {
            dispatch(getExpRecAPIData(interviewId));
        }, 0);
    }

    const searchExpertNameHandler = (expertName, updatedFilters, resetGridFlag, searchFieldCallFlag) => {
        if ((searchFieldCallFlag && expertName.length > 2) || !searchFieldCallFlag || resetGridFlag) {
            updatedFilters = updatedFilters ? updatedFilters : filters;
            if (loading) {
                return
            }
            setData([]);
            setPage(0);
            if (!resetGridFlag) {
                setLastPage(true);
            }
            setFilters({ ...updatedFilters, ['expertName']: expertName });
            setSelectedTagsStatus([]);
            dispatchGridCall(expertName, updatedFilters, 0, 10, selectedFilterStatus, undefined);
        }
    }

    const handleChangeFilter = (column, value) => {
        const newFilters = filters[column]?.includes(value)
            ? filters[column].filter(item => item !== value)
            : [...filters[column], value];

        setFilters({ ...filters, [column]: newFilters });
        let tempFilters = { ...filters, [column]: newFilters }
        searchExpertNameHandler(expertNameSearchTerm, tempFilters, false, false);
    }

    const handleSelectAll = (selectAllParam, selFilter) => {
        if (loading) {
            return;
        }
        setPage(0);

        const updateFilters = (key) => {
            const updatedFilter = filters[key].length === selFilter.length ? [] : selFilter.map(item => item.name);
            setFilters({ ...filters, [key]: updatedFilter });
        };

        const filterMapping = {
            'expertTypesAll': 'expertTypes',
            'networkProvidersAll': 'networkProviders',
            'companiesAll': 'companies',
            'countriesAll': 'countries',
            'costAll': 'cost'
        };

        const filterKey = filterMapping[selectAllParam];
        if (filterKey) {
            updateFilters(filterKey);
        }
    };

    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }

    const handleSearchChange = (event, type) => {
        event.stopPropagation();
        // Update the search term
        const searchTerm = event?.target?.value?.toLowerCase();
        if (type === 'company') {
            setCompanySearchTerm(event?.target?.value);
            setCountrySearchTerm('');
            let companiesFiltersTempResult = companiesInitialFilters?.filter((item) => {
                return item?.name?.toLowerCase().includes(searchTerm);
            });
            setCompaniesFilters(companiesFiltersTempResult);
        }
        if (type === 'country') {
            setCountrySearchTerm(event.target.value);
            setCompanySearchTerm('');
            let countriesFiltersTempResult = countriesInitialFilters?.filter((item) => {
                return item?.name?.toLowerCase().includes(searchTerm);
            });
            setCountriesFilters(countriesFiltersTempResult);
        }
        if (type === 'expertName') {
            setCompanySearchTerm('');
            setCountrySearchTerm('');
            setExpertNameSearchTerm(event?.target?.value);
        }
    };


    const uniqueValuesForColumn = (column) => {
        switch (column) {
            case 'companies':
                return companiesFilters;
            case 'countries':
                return countriesFilters;
            case 'expertTypes':
                return expertTypesFilters;
            case 'networkProviders':
                return networkProvidersFilters;
            case 'costs':
                return costFilter;
        }
    };

    const handleCheckBoxChange = (event, inputData) => {
        if (inputData.checked) {
            setSelectedCheckBoxes(prev => [...prev, inputData?.label?.profileId]);
        } else {
            setSelectedCheckBoxes(prev => prev.filter(item => item !== inputData?.label?.profileId));
        }
    }

    const handleClearFilter = (column) => {
        let expertNameString = expertNameSearchTerm;
        let updatedFilters = filters;
        if (column == 'expertName') {
            setExpertNameSearchTerm('');
            expertNameString = '';
            setFilters({ ...filters, [column]: '' });
        } else {
            setFilters({ ...filters, [column]: [] });
            updatedFilters = { ...filters, [column]: [] };
        }
        toggleCrossIcon(column, false);
        searchExpertNameHandler(expertNameString, updatedFilters, true, false);
    };

    const handleCrossIconClick = (type) => {
        setDropdownOpen({
            networkProviders: false,
            expertTypes: false,
            countries: false,
            cost: false,
            companies: false,
            expertName: false
        });
        setCrossIconClick(true);
        setExpertNameSearchTerm(filters['expertName']);
        toggleCrossIcon(type, false);
        setCompanySearchTerm('');
        setCountrySearchTerm('');
        setCompaniesFilters(expRecFiltersData?.companies);
        setCountriesFilters(expRecFiltersData?.countries);
    };

    const toggleCrossIcon = (column, flag) => {
        switch (column) {
            case 'cost':
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: true,
                    countries: false,
                    [column]: flag,
                    companies: false
                })
                break;

            case 'networkProviders':
                setShowCrossIcon({
                    [column]: flag,
                    expertTypes: false,
                    countries: false,
                    cost: false,
                    companies: false
                })
                break;

            case 'expertTypes':
                setShowCrossIcon({
                    networkProviders: false,
                    [column]: flag,
                    countries: false,
                    cost: false,
                    companies: false
                })
                break;

            case 'companies':
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: false,
                    countries: false,
                    cost: false,
                    [column]: flag
                })
                break;

            case 'countries':
                setShowCrossIcon({
                    networkProviders: false,
                    expertTypes: false,
                    [column]: flag,
                    cost: false,
                    companies: false
                });
                break;

            case 'expertName':
                setShowCrossIcon({
                    ...showCrossIcon,
                    [column]: flag
                });
                break;
        }
    };

    const handleAllCheckChange = () => {
        let temp = [];
        let tempData = data;
        if (selectedCheckBoxes?.length === tempData?.length) {
            setSelectedCheckBoxes([]);
        }
        else {
            data?.map((item) => {
                temp?.push(item?.profileId);
            });
        }

        setSelectedCheckBoxes(temp);
    }

    const handleCarouselActionClicked = (action, selectionIndex) => {
        let newSelectedIndex = selectionIndex;
        if (data?.length > 0) {
            if (action == 'next') {
                newSelectedIndex = (selectionIndex + 1);
            } else {
                if (selectionIndex > 0) {
                    newSelectedIndex = (selectionIndex - 1);
                }
            }

            onExpertNameClicked(data[newSelectedIndex]);
            setOpenExpertProfile({
                open: true,
                selectedIndex: newSelectedIndex,
                selectedRecord: data[newSelectedIndex],
                lastRecord: (newSelectedIndex + 1) == data.length
            });
        }
    }

    const handlerRequestCallClick = (selection) => {
        if (selection) {
            const selectedRecord = data.filter((item) => item.id == selection);
            if (selectedRecord?.length > 0) {
                setSelectedExpert(selectedRecord[0]);
            }
        }
        openCallScheduler();
    }

    const openDuplicateProfiles = (selected) => {
        handleExpertProfileClose();
        if (selected) {
            setOpenComparePopup({
                isOpen: true,
                type: 'duplicate',
                selection: selected
            });
        }
    }

    const onExpertNameClicked = (item) => {
        setSelectedExpert(item);
    }

    const handleProfileStatus = (item) => {
        const { profileStatus, networkComment } = item;
        const isLongStatus = profileStatus?.length > 13;
        const isNotAvailableWithComment = profileStatus === "Not Available" && networkComment;

        if (!profileStatus?.length) return null;

        const tooltipContent = isLongStatus ? profileStatus : isNotAvailableWithComment ? networkComment : null;
        const statusTooltipId = tooltipContent ? tooltipId : null;

        return (
            <>
                <div
                    className="expertName--status"
                    data-tooltip-id={statusTooltipId}
                    data-tooltip-content={tooltipContent}
                >
                    {profileStatus}
                </div>
                {tooltipContent && (
                    <ReactTooltip
                        id={tooltipId}
                        place="bottom"
                        effect="solid"
                        className="expert--tooltip"
                    />
                )}
            </>
        );
    };


    const handleStatusFilterSelection = (event, selection) => {
        setSelectedFilterStatus(prev => {
            const updatedStatusArr = selection.checked
                ? [...prev, selection.label]
                : prev.filter(item => item !== selection.label);
            setSelectedTagsStatus([]);
            dispatchGridCall(filters['expertName'], filters, 0, 10, updatedStatusArr, undefined);

            return updatedStatusArr;
        });
    };

    const handleTagsFilterSelection = (event) => {
        const tagName = event.target.getAttribute('data-tag');
        setSelectedCheckBoxes([]);
        setSelectedFilterStatus([]);
        let obj = {
            'expertName': '',
            'expertTypes': [],
            'companies': [],
            'countries': [],
            'networkProviders': [],
            'cost': []
        }
        setFilters({
            'expertName': '',
            'expertTypes': [],
            'companies': [],
            'countries': [],
            'networkProviders': [],
            'cost': []
        });
        setSelectedFilterStatus([]);
        setSelectedValue(sortByValues[0]?.value);
        setPage(0);
        let arr = [];
        if (tagName === selectedTagsStatus[0]) {
            setSelectedTagsStatus([]);
            dispatchGridCall('', obj, 0, 10, [], undefined);
        }
        else {
            arr?.push(tagName);
            setSelectedTagsStatus(arr);
            dispatchGridCall('', obj, 0, 10, [], tagName);
        }

    };


    const profilesActionHandler = (type) => {
        if (selectedCheckBoxes?.length > 0) {
            let actionList = [];
            selectedCheckBoxes.forEach((selection) => {
                const record = data.filter(item => item.profileId == selection);
                if (record) {
                    actionList.push(record[0]?.id);
                }
            });
            setPage(0);
            setSelectedCheckBoxes([]);
            setSelectedFilterStatus([]);
            setMultipleActionsMadeFlag(false);
            if (type === 'decline') {
                dispatch(setExpertProfileActionsAPIData({ "expertProfileIds": actionList, "action": type, "comment": declinedReason }));
                setDeclinedReason('');
            }
            else {
                dispatch(setExpertProfileActionsAPIData({ "expertProfileIds": actionList, "action": type }));
            }
        }
    }

    const openCompareExpertProfiles = () => {
        if (selectedCheckBoxes?.length > 0) {
            let selectedProfiles = [];
            selectedCheckBoxes.forEach((selection) => {
                const record = data.filter(item => item.profileId == selection);
                if (record) {
                    selectedProfiles.push(record[0]);
                }
            });
            setOpenComparePopup({
                isOpen: true,
                type: 'compare',
                selection: selectedProfiles
            });
        }
    }

    const closeCompareExpertProfiles = () => {
        setOpenComparePopup({
            isOpen: false,
            type: '',
            selection: []
        });
        setSelectedCheckBoxes([]);
    }
    const refreshProfiles = () => {
        const size = (page + 1) * 10;
        dispatchGridCall(filters['expertName'], filters, 0, size, undefined);
    }

    const handleRemoveSelection = (profileId, type) => {
        if (type == 'remove') {
            setSelectedCheckBoxes(selectedCheckBoxes.filter(item => item == profileId));
        } else {
            setSelectedCheckBoxes([...selectedCheckBoxes, profileId]);
            if (profileId) {
                const record = data.filter(item => item.profileId == profileId);
                if (record) {
                    setOpenComparePopup({
                        ...openComparePopup,
                        selection: [...openComparePopup.selection, record[0]]
                    });
                }
            }
        }
    }

    const tooltipId = 'tooltip-id';

    const saveComment = (profile, comment) => {
        setCommentSavedFlag(false);
        dispatch(setExpertProfileActionsAPIData({ "expertProfileId": profile, "action": "comment", "userComment": comment }));
    }

    const commentsLinkClickHandler = (selection) => {
        if (selection?.id) {
            let commentsDetails = [];
            if (selection.userComments?.length > 0) {
                commentsDetails = [...selection.userComments];
            }
            setCommentExpName(selection?.expertName);
            setCommentsPanelDetails({ open: true, savedComments: commentsDetails, profileId: selection.id });
        }
    }

    const handleClosePopup = (flag) => {
        if (flag) {
            setShowDownload({ open: false });
        } else {
            dispatch(downloadExpertsAPIData(interviewId));
        }
    }

    return (
        <>
            {openScheduleCall && selectedExpert && <ScheduleCall
                open={openScheduleCall}
                data={selectedExpert}
                caseTeamMembers={caseTeamMembers}
                requestId={interviewId}
                closePopupHandler={() => handleSchedulePopupClose()}
            />}
            {openExpertProfileFlag?.open && selectedExpert && <ExpertProfile
                open={openExpertProfileFlag.open}
                isClosedRequest={isClosedRequest}
                selectedIndex={openExpertProfileFlag.selectedIndex}
                selectedRecord={openExpertProfileFlag.selectedRecord}
                lastRecord={openExpertProfileFlag.lastRecord}
                data={selectedExpert}
                allData={data}
                duplicateProfiles={openExpertProfileFlag.duplicateProfiles}
                refreshProfileHandler={refreshProfiles}
                closePopupHandler={() => handleExpertProfileClose()}
                carouselActionClicked={(action, selection) => handleCarouselActionClicked(action, selection)}
                openScheduler={() => handlerRequestCallClick(null)}
                handleDuplicateProfilesClick={(selected) => openDuplicateProfiles(selected)}
            />}
            {
                openComparePopup?.isOpen &&
                <CompareProfiles
                    popupType={openComparePopup.type}
                    queryData={interviewId}
                    isClosedRequest={isClosedRequest}
                    showPopup={openComparePopup.isOpen}
                    selectedProfile={openComparePopup.selection}
                    handleClosePopup={closeCompareExpertProfiles}
                    refreshProfileHandler={refreshProfiles}
                    removeFromMainSelection={(profileId, type) => { if (openComparePopup.type == 'compare') { handleRemoveSelection(profileId, type) } }}
                    openScheduler={(selection) => handlerRequestCallClick(selection)}
                />
            }
            <CommentsPane
                config={commentsPanelDetails}
                sliderCloseHandler={() => setCommentsPanelDetails({ open: false, savedComments: [], profileId: -1 })}
                saveCommentHandler={(profileId, newComment) => saveComment(profileId, newComment)}
                selectedExpert={commentExpName}
            />
            {
                getShowDownload?.open &&
                <ActionAlertWindow props={getShowDownload} closeAlertWindowHandler={(flag) => handleClosePopup(flag)} >
                </ActionAlertWindow>
            }

            <div className="expertsReceived__wrap">
                <div className='expertsReceived__head'>
                    <div className="grid-header-count">
                        <div className='count--badge'>{total || '0'}</div>
                        Expert Profiles Recieved
                    </div>

                    <div className='download__sort'>
                        <>
                            {
                                noSearchRecFound === false &&
                                <>
                                    <div className="profile--actions download--profiles" onClick={() =>
                                        setShowDownload({
                                            open: true,
                                            details: 'By downloading this file, you agree and must ensure that the data is not stored on your computer and is permanently deleted from the case team folder within 90 days after the case is closed.',
                                            cancelActionTxt: 'No, Cancel',
                                            proceedActionTxt: 'Yes, Download',
                                            noInputFields: true
                                        })}>
                                        <Image src={DownloadIcon} className='download--img' alt="DownloadIcon" />
                                        Download Profiles
                                    </div>
                                    {selectedCheckBoxes?.length != 0 &&
                                        <button disabled={isClosedRequest} className="profile--actions shortlist--profiles" onClick={() => { profilesActionHandler('shortlist'); }}>
                                            <Image src={StarOutlineIcon} className='download--img' alt="StarIcon" />
                                            Shortlist
                                        </button>}
                                    {
                                        selectedCheckBoxes?.length > 20 && <>
                                            <ReactTooltip id='disabled-compare-button' place="bottom" effect="solid" className="expert--tooltip" />
                                            <div className="profile--actions disabled-profile-action"
                                                data-tooltip-id='disabled-compare-button'
                                                data-tooltip-content={'Comparisons are available for a maximum of 20 profiles at a time.'}>
                                                <Image src={CompareIcon} className='download--img' alt="CompareIcon" />
                                                Compare
                                            </div>
                                        </>
                                    }
                                    {
                                        selectedCheckBoxes?.length > 1 && selectedCheckBoxes?.length < 21 &&
                                        <div className="profile--actions shortlist--profiles"
                                            onClick={() => { openCompareExpertProfiles() }}
                                        >
                                            <Image src={CompareIcon} className='download--img' alt="CompareIcon" />
                                            Compare
                                        </div>
                                    }
                                    {
                                        selectedCheckBoxes?.length !== 0 &&
                                        <div style={{ position: 'relative' }} className="no--border">
                                            <Popup
                                                content={
                                                    <>
                                                        <div className="tooltip--headWrap">
                                                            <div className='tooltip--head'>Reason for declining <span className="tooltip--optional">(Optional)</span></div>
                                                            <img className="green-icon" src={GreenCrossIcon} alt="close"
                                                                onClick={() => {
                                                                    setVisible(false);
                                                                    setSelectedCheckBoxes([]);
                                                                    setDeclinedReason('')
                                                                }}
                                                            />
                                                        </div>
                                                        <input type='text' placeholder="Reason for declining" className="decline--input" onChange={(e) => setDeclinedReason(e?.target?.value)} />
                                                        <div className="tooltip--btns">
                                                            <button onClick={() => {
                                                                profilesActionHandler('decline');
                                                                setVisible(false);  // Close popup after submit
                                                            }} className="default-button">Submit</button>
                                                        </div>
                                                    </>
                                                }
                                                trigger={
                                                    <button
                                                        className="profile--actions decline--profiles"
                                                        onClick={() => { setVisible(true); }}
                                                        disabled={data.profileStatus === "Declined" || isClosedRequest}
                                                    >
                                                        {data?.profileStatus === 'Declined' ? (
                                                            <>
                                                                <Image src={MinusIcon} alt="star icon" /> Declined
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Image src={MinusIcon} alt="star icon" /> Decline
                                                            </>
                                                        )}
                                                    </button>
                                                }
                                                on="click"
                                                position="bottom right"
                                                open={visible}
                                                onClose={() => setVisible(false)}
                                                className="popup-tooltip"
                                            />
                                        </div>
                                    }
                                </>
                            }
                            <>
                                <div className="sortBy__wrap">
                                    <div className="sortBy--head">Sort By</div>
                                    <Dropdown
                                        className="sortBy--drop"
                                        placeholder='Sort By'
                                        fluid
                                        selection
                                        value={selectedValue}
                                        options={sortByValues}
                                        onChange={handleSortByChange}
                                        icon={<GreenIcon />} />
                                </div>
                            </></>
                    </div>
                </div>
                {
                    noSearchRecFound === false &&
                    <div className="expertsReceived__top-filters">

                        <div className={`top-filter-name ${selectedTagsStatus?.includes('PreferredNetworks') && 'selected'}`}
                            data-tag="PreferredNetworks"
                            onClick={handleTagsFilterSelection}>BCG Preferred Networks &nbsp;&nbsp;&nbsp; {bcgPreferredNetwork}</div>

                        <div className={`top-filter-name ${selectedTagsStatus?.includes('Recommended') && 'selected'}`}
                            data-tag="Recommended"
                            onClick={handleTagsFilterSelection}
                        ><Image src={BadgeIcon} alt="BadgeIcon" className='badge--icon' /> Network Recommended &nbsp;&nbsp;&nbsp;{networkRecommended}</div>

                        <div className={`top-filter-name profile--filter ${isFilterStatusPopupOpen && 'status-filter-open'}`}
                            ref={filterRef}
                            onClick={(event) => {
                                event.stopPropagation();
                                setFilterStatusPopupOpen(!isFilterStatusPopupOpen);
                            }} >Profile Status &nbsp;<Image src={isFilterStatusPopupOpen ? UpArrow : DownArrow} alt="Down Icon" className='badge--icon dropdown-arrow' />
                            {
                                isFilterStatusPopupOpen &&
                                <div className="filter-status-popup" ref={statusPopupRef}>
                                    <ul>
                                        {statusData?.map((option, popupIndex) => (
                                            <li key={popupIndex} >
                                                <Checkbox className='een-checkbox table--filter-check' label={option.name}
                                                    onChange={(event, selection) => handleStatusFilterSelection(event, selection)} checked={selectedFilterStatus.includes(option.name)} />
                                                {option.count > -1 && <span>{option.count}</span>}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className={`top-filter-name ${selectedTagsStatus?.includes('Shortlisted') && 'selected'}`}
                            data-tag="Shortlisted"
                            onClick={handleTagsFilterSelection}><Image src={StarIcon} alt="StarIcon" className='badge--icon' /> Shortlisted &nbsp;&nbsp;&nbsp;{shortlisted}</div>

                        <div className={`top-filter-name ${selectedTagsStatus?.includes('Duplicates') && 'selected'}`}
                            data-tag="Duplicates"
                            onClick={handleTagsFilterSelection}
                        >Duplicates &nbsp;&nbsp;&nbsp;{duplicate}</div>
                    </div>
                }
                {
                    <>
                        <Table sortable celled className='calls--table'>
                            <Table.Header className='completedcalls__header'>
                                <Table.Row>
                                    <Table.HeaderCell className='header--sorttitle'>
                                        <Checkbox onChange={handleAllCheckChange} checked={data?.length === selectedCheckBoxes?.length && data?.length > 0} className='een-checkbox tableAll--check' />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border user--name'>
                                        <div className='header--div' ref={dropdownRefs['expertName']}>
                                            <div className='header--divText' id='exp-name-header'>
                                                <span className='header--text'>
                                                    Expert Name
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters && filters['expertName'] &&
                                                    <div className='filter--count'><Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertName')} /></div>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertName']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                expertName: true,
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false
                                                            });
                                                            setShowCrossIcon({
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: true
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertName'] === true ? CrossIcon : SearchIcon} alt="icon" className={`'filter--icon' ${dropdownOpen['expertName'] && 'filtercross--icon'}`}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertName');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={'calls--menu calls--menuLessData expertName--menu'}>
                                                        <Dropdown.Item key={0}>
                                                            <div className="dropdown--search">
                                                                <Dropdown.SearchInput type="string"
                                                                    value={expertNameSearchTerm}
                                                                    onChange={(e) => handleSearchChange(e, 'expertName')}
                                                                    onKeyUp={(e) => {
                                                                        handleKeyUp(e);
                                                                    }
                                                                    }
                                                                    placeholder="Search By Experts Name"
                                                                    className="search--input paddingnew" />
                                                                <Image src={GreenSearchIcon} alt="search icon" className={`search--icon greensearch--icon ${expertNameSearchTerm.length < 3 && "disable-icon"}`}
                                                                    onClick={() => searchExpertNameHandler(expertNameSearchTerm, '', false, true)} />
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['expertTypes']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Expert Types / Modules
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.expertTypes?.length > 0 &&
                                                    <span className='filter--count'>{filters?.expertTypes?.length} <Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('expertTypes')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['expertTypes']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                networkProviders: false,
                                                                expertTypes: true,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            });
                                                            setShowCrossIcon({
                                                                networkProviders: false,
                                                                expertTypes: true,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['expertTypes'] === true ? CrossIcon : FilterIcon} alt="icon" className={`'filter--icon' ${dropdownOpen['expertTypes'] && 'filtercross--icon'}`}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('expertTypes');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={expertTypesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            expertTypesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('expertTypesAll', expertTypesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['expertTypes']?.length === expertTypesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['expertTypes']?.length === expertTypesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {expertTypesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value} onClick={() => handleChangeFilter('expertTypes', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['expertTypes']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['expertTypes']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>
                                                                                        {
                                                                                            value?.name
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('expertTypes')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs['companies']}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Relevant Company
                                                </span></div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.companies?.length > 0 &&
                                                    <span className='filter--count'>{filters?.companies?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('companies')} /></span>
                                                }

                                                <Dropdown
                                                    open={dropdownOpen['companies']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: true,
                                                                expertName: false
                                                            });
                                                            setShowCrossIcon({
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: true,
                                                                expertName: false
                                                            });
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['companies'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['companies'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('companies');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon"
                                                >
                                                    <Dropdown.Menu className={companiesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>
                                                        <div className="dropdown--search">
                                                            <Dropdown.SearchInput type="string"
                                                                value={companySearchTerm}
                                                                onChange={(e) => handleSearchChange(e, 'company')}
                                                                onKeyUp={(e) => {
                                                                    handleKeyUp(e);
                                                                }
                                                                }
                                                                placeholder="Search Relevant Company"
                                                                className="search--input" />
                                                            <Image src={SearchIcon} alt="search icon" className="search--icon" />
                                                        </div>
                                                        {
                                                            companiesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('companiesAll', companiesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['companies']?.length === companiesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['companies']?.length === companiesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {companiesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value} onClick={() => handleChangeFilter('companies', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['companies']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['companies']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>
                                                                                        {value?.name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('companies')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div'>
                                            <div className='header--divText header-2words'>
                                                Relevant Position
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border countryHead'>
                                        <div className='header--div' ref={dropdownRefs?.countries}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Country
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>
                                                {
                                                    filters?.countries?.length > 0 &&
                                                    <span className='filter--count statuscount'>{filters?.countries?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('countries')} /></span>
                                                }
                                                <Dropdown
                                                    open={dropdownOpen['countries']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: true,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            });
                                                            setShowCrossIcon({
                                                                networkProviders: false,
                                                                expertTypes: false,
                                                                countries: true,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['countries'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['countries'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('countries');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">
                                                    <Dropdown.Menu className={countriesFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>
                                                        <div className="dropdown--search">
                                                            <Dropdown.SearchInput type="string"
                                                                value={countrySearchTerm}
                                                                onChange={(e) => handleSearchChange(e, 'country')}
                                                                onKeyUp={(e) => {
                                                                    handleKeyUp(e);
                                                                }
                                                                }
                                                                placeholder="Search Relevant Country"
                                                                className="search--input" />
                                                            <Image src={SearchIcon} alt="search icon" className="search--icon" />
                                                        </div>

                                                        {
                                                            countriesFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('countriesAll', countriesFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['countries']?.length === countriesFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['countries']?.length === countriesFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {countriesFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value} onClick={() => handleChangeFilter('countries', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['countries']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['countries']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>
                                                                                        {value?.name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('countries')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div'>
                                            <div className='header--divText'>
                                                Expert Highlights / Biography
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div'>
                                            <div className='header--divText'>
                                                Screening Responses
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border'>
                                        <div className='header--div' ref={dropdownRefs?.networkProviders}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Network
                                                </span>
                                            </div>
                                            <div className='filter--countwrap'>{
                                                filters?.networkProviders?.length > 0 &&
                                                <span className='filter--count statuscount'>{filters?.networkProviders?.length}<Image src={WhiteCrossIcon} alt="CrossIcon" className='cross--icon' onClick={() => handleClearFilter('networkProviders')} /></span>
                                            }
                                                <Dropdown
                                                    open={dropdownOpen['networkProviders']}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!clickOutside) {
                                                            setDropdownOpen({
                                                                networkProviders: true,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            });
                                                            setShowCrossIcon({
                                                                networkProviders: true,
                                                                expertTypes: false,
                                                                countries: false,
                                                                cost: false,
                                                                companies: false,
                                                                expertName: false
                                                            })
                                                        }
                                                        else {
                                                            setClickOutside(false);
                                                        }
                                                    }}
                                                    closeOnChange={false}
                                                    icon={<Image src={showCrossIcon['networkProviders'] === true ? CrossIcon : FilterIcon} alt="icon" className={dropdownOpen['networkProviders'] ? 'filter--icon filtercross--icon' : 'filter--icon'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCrossIconClick('networkProviders');
                                                        }
                                                        }
                                                    />}
                                                    floating
                                                    labeled
                                                    button
                                                    className="icon">
                                                    <Dropdown.Menu className={networkProvidersFilters?.length > 2 ? 'calls--menu calls--menumoreData' : 'calls--menu calls--menuLessData'}>

                                                        {
                                                            networkProvidersFilters?.length > 0 ?
                                                                <>
                                                                    <>
                                                                        <Dropdown.Item key={-1}
                                                                            onClick={() => handleSelectAll('networkProvidersAll', networkProvidersFilters)}
                                                                            className='filter--item'>
                                                                            <div className='filter__chk'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={filters['networkProviders']?.length === networkProvidersFilters?.length}
                                                                                    className='filter--checkBox'
                                                                                />
                                                                                <div className={filters['networkProviders']?.length === networkProvidersFilters?.length ? 'filter--itemtext selected' : 'filter--itemtext'}>Select All</div>
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Divider className='drop--divider' />
                                                                    </>
                                                                    {networkProvidersFilters?.map((value, index) => (
                                                                        <>
                                                                            <Dropdown.Item key={value} onClick={() => handleChangeFilter('networkProviders', value?.name)}
                                                                                className='filter--item'>
                                                                                <div className='filter__chk'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={filters['networkProviders']?.includes(value?.name)}
                                                                                        className='filter--checkBox' />
                                                                                    <div className={filters['networkProviders']?.includes(value) ? 'filter--itemtext selected' : 'filter--itemtext'}>
                                                                                        {value?.name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='filter--countText'>{value?.count}</div>
                                                                            </Dropdown.Item>
                                                                            <>{index !== uniqueValuesForColumn('networkProviders')?.length - 1 && <Dropdown.Divider className='drop--divider' />}</>
                                                                        </>
                                                                    ))}
                                                                </>
                                                                :
                                                                <Dropdown.Item className='filter--item'>No Filters Available</Dropdown.Item>

                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title completedcalls__header-border countryHead'>
                                        <div className='header--div' ref={dropdownRefs?.cost}>
                                            <div className='header--divText'>
                                                <span className='header--text'>
                                                    Cost
                                                </span>
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='exp-header--title'>
                                        <div className='header--div' id="exp-comment-header">
                                            <div className='header--divText'>
                                                Comment
                                            </div>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <div id="scrollable-container">
                            <InfiniteScroll
                                dataLength={data?.length || 0}
                                hasMore={hasMore}
                                scrollableTarget="scrollable-container"
                                next={loadMoreData}
                                className='commTemplates__infiniteScroll'
                            >
                                <Table sortable celled className='calls--table'>
                                    <Table.Body className='completedcalls__body'>
                                        {
                                            (loading === true && noSearchRecFound === false) ? (
                                                <div className='loading--center'>
                                                    <ImageParaSkeleton showPara={true} paraCount={5} size="fullWidth" height={4} />
                                                </div>
                                            ) : noSearchRecFound === true ? (
                                                <div className='noRecFound'>
                                                    Currently, there are no experts for the selected filters.
                                                </div>
                                            ) : (
                                                <>
                                                    {data?.map((item, index) => (
                                                        <Table.Row key={index} className={item?.profileStatus?.toLowerCase() === 'new' && !item?.shortlisted && !item.userViewed ? "exp--row rowShortlistedClr" : "exp--row rowDefaultClr"}>
                                                            <Table.Cell className='table-cell tablebody--check noBorderRight'>
                                                                <div className="check-container">
                                                                    {item?.duplicate && <div className="show--duplicate">Duplicate</div>}
                                                                    <Checkbox label={item} onChange={handleCheckBoxChange} checked={selectedCheckBoxes?.includes(item?.profileId)} className='een-checkbox table--check' />
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className='user--name table--cell req--id fixed-column noBorderLeft' onClick={() => openExpertProfile(item, index)}>
                                                                <div className="expertName__data">
                                                                    <div className="expertName__badges">
                                                                        {item?.recommended === true && (
                                                                            <div>
                                                                                <Image
                                                                                    src={BadgeIcon}
                                                                                    alt="BadgeIcon"
                                                                                    className="badge--icon"
                                                                                    data-tooltip-id={tooltipId}
                                                                                    data-tooltip-content={'Network Recommended'}
                                                                                />
                                                                                <ReactTooltip id={tooltipId} place="bottom" effect="solid" className="expert--tooltip" />
                                                                            </div>
                                                                        )}
                                                                        {item?.shortlisted === true && (
                                                                            <div>
                                                                                <Image
                                                                                    src={StarIcon}
                                                                                    alt="Star Icon"
                                                                                    className="badge--icon"
                                                                                    data-tooltip-id={tooltipId}
                                                                                    data-tooltip-content={'Shortlisted'}
                                                                                />
                                                                                <ReactTooltip id={tooltipId} place="bottom" effect="solid" className="expert--tooltip" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="expertName__wrap">
                                                                        <div className="expertName" data-tooltip-id={item?.expertName} data-tooltip-content={item?.expertName} id={'ename' + index} onClick={() => onExpertNameClicked(item)}>{item?.expertName?.length > 25 ? item?.expertName?.substring(0, 25)?.concat('...') : item?.expertName}
                                                                            {
                                                                                item?.expertName?.length > 25 &&
                                                                                <ReactTooltip id={item?.expertName} place="bottom-start" className="expert--tooltip" />
                                                                            }
                                                                        </div>
                                                                        {(item?.profileStatus === 'New' && item?.userViewed === true) ? '' : handleProfileStatus(item)}
                                                                    </div>
                                                                </div>
                                                            </Table.Cell>
                                                            <Table.Cell className='user--data table--cell' data-tooltip-id={item?.expertTypes.join(', ')} data-tooltip-content={item?.expertTypes.join(', ')}>{Array.isArray(item?.expertTypes) ? (item?.expertTypes?.length > 45 ? (item?.expertTypes.join(', ')?.substring(0, 45)?.concat('...')) : item?.expertTypes.join(', ')) : 'NA'}
                                                                {
                                                                    item?.expertTypes?.join(', ')?.length > 45 &&
                                                                    <ReactTooltip id={item?.expertTypes.join(', ')} place="bottom-start" className="expert--tooltip" />
                                                                }
                                                            </Table.Cell>

                                                            <Table.Cell className='user--data table--cell' data-tooltip-id={item?.relevantExperience?.employerName} data-tooltip-content={item?.relevantExperience?.employerName}>{
                                                                item?.relevantExperience?.employerName?.length > 0 ? (item?.relevantExperience?.employerName?.length > 45 ? item?.relevantExperience?.employerName?.substring(0, 45)?.concat('...') : item?.relevantExperience?.employerName) : 'NA'}
                                                                {
                                                                    item?.relevantExperience?.employerName?.length > 45 &&
                                                                    <ReactTooltip id={item?.relevantExperience?.employerName} place="bottom-start" className="expert--tooltip" />

                                                                }
                                                            </Table.Cell>

                                                            <Table.Cell className='user--data table--cell' data-tooltip-id={item?.relevantExperience?.title} data-tooltip-content={item?.relevantExperience?.title}>
                                                                {item?.relevantExperience?.title?.length > 30 ? item?.relevantExperience?.title?.substring(0, 30)?.concat('...') : item?.relevantExperience?.title}
                                                                <br />
                                                                (
                                                                {moment().set('month', (item?.relevantExperience?.startMonth ?? 0) - 1).format('MMM')}
                                                                &nbsp;{item?.relevantExperience?.startYear ?? ''}
                                                                {item?.relevantExperience?.endMonth !== null ? (
                                                                    <>
                                                                        &nbsp;-&nbsp;{moment().set('month', (item?.relevantExperience?.endMonth ?? 0) - 1).format('MMM')}
                                                                        &nbsp;{item?.relevantExperience?.endYear ?? ''}
                                                                    </>
                                                                ) : item?.relevantExperience?.employerStatus === 'Current' ? <>&nbsp;-&nbsp;Present</> : ''}
                                                                )
                                                                {
                                                                    item?.relevantExperience?.title?.length > 30 &&
                                                                    <ReactTooltip id={item?.relevantExperience?.title} place="bottom-start" className="expert--tooltip" />

                                                                }
                                                            </Table.Cell>


                                                            <Table.Cell className='user--data table--cell countryWidth'>{item?.country || 'NA'}</Table.Cell>
                                                            <Table.Cell className='user--data table--cell'
                                                                data-tooltip-id={generateRandomKey}
                                                                data-tooltip-content={item?.biography}>
                                                                {
                                                                    item?.biography?.length > 0 ? (item?.biography?.length > 45 ? item?.biography?.substring(0, 45)?.concat('...') : item?.biography) : 'NA'}
                                                                {
                                                                    item?.biography?.length > 45 &&
                                                                    <ReactTooltip
                                                                        id={generateRandomKey}
                                                                        place="bottom-start"
                                                                        className="expert--tooltip"
                                                                    />
                                                                }
                                                            </Table.Cell>
                                                            <Table.Cell data-tooltip-id={item?.screeningQuestions && item?.screeningQuestions[0]?.question} data-tooltip-content={handleTooltipData(item)} className='user--data table--cell screeningWidth'>
                                                                <>{
                                                                    item?.screeningQuestions && item?.screeningQuestions[0]?.question?.length > 0 ?
                                                                        <>
                                                                            {item?.screeningQuestions && item?.screeningQuestions[0]?.question?.length > 0 && (
                                                                                <>
                                                                                    Q: {item?.screeningQuestions[0]?.question?.length > 15 ? item?.screeningQuestions[0]?.question?.substring(0, 15)?.concat('...') : item?.screeningQuestions[0]?.question}
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                            {item?.screeningQuestions && item?.screeningQuestions[0]?.answer?.length > 0 && (
                                                                                <>
                                                                                    A: {item?.screeningQuestions[0]?.answer?.length > 8 ? item?.screeningQuestions[0]?.answer?.substring(0, 8)?.concat('...') : item?.screeningQuestions[0]?.answer}
                                                                                    <ReactTooltip id={item?.screeningQuestions && item?.screeningQuestions[0]?.question} place="bottom-start" className="expert--tooltip" />
                                                                                </>
                                                                            )}
                                                                        </>
                                                                        :
                                                                        'NA'
                                                                }</>
                                                            </Table.Cell>
                                                            <Table.Cell className='user--data table--cell'>{item?.networkProvider || 'NA'}</Table.Cell>
                                                            <Table.Cell className='user--data table--cell costWidth'>{`$${item?.rate?.costPerHour}/hr`} <br /> {`$${item?.rate?.costPer30Mins}/30 min`}
                                                                <br />
                                                                ({item?.rate?.rateType})
                                                            </Table.Cell>
                                                            <Table.Cell id={'comment' + index} className='user--data table--cell commentWidth'>
                                                                <>
                                                                    <div className="comment-data" data-tooltip-id={'commentTooltip'} data-tooltip-content={item?.userComments?.[0]?.comment}
                                                                    >{item?.userComments?.[0]?.comment?.length > 30 ? item?.userComments?.[0]?.comment?.substring(0, 30)?.concat('...') : item?.userComments?.[0]?.comment}</div>
                                                                    {
                                                                        item?.userComments?.[0]?.comment?.length > 30 &&
                                                                        <ReactTooltip id={'commentTooltip'} place="bottom-start" className="expert--tooltip" />
                                                                    }
                                                                </>
                                                                <div className="bordered--cell2 comment-link" onClick={() => { commentsLinkClickHandler(item) }}><Image src={CommentIcon} /><span>{item?.userComments?.[0]?.comment ? 'View All' : 'Comments'}</span></div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </>
                                            )
                                        }
                                    </Table.Body>

                                </Table>
                            </InfiniteScroll>

                        </div></>
                }

            </div>
        </>
    );
};