import React, { useEffect, useRef, useState } from "react";
import Image from "components/shared/Image";
import PhoneIcon from 'assets/images/phone-blue.svg';
import UserIcon from 'assets/images/user.svg';
import MentionIcon from 'assets/images/mention.svg';
import caseIcon from "assets/images/cases.svg";
import ExpertProfile from "assets/images/expert-profile.svg";
import LikeIcon from "assets/images/like.svg";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import { getRandomColor } from "utils/helpers/helpers";
import './UserProfile.scss';

export const UserProfile = () => {
    const [userProfile, setUserProfile] = useState('');
    const [userName, setUserName] = useState('');
    const [userDesg, setUserDesg] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userDetails, setUserDetails] = useState({ nameInitials: '', bgColor: '' });
    useEffect(() => {
        fetchLoggedInUserEmail();
    }, []);

    const fetchLoggedInUserEmail = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            let profilePicture = resp?.[0]?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : resp?.[0]?.picture;
            const nameArray = resp[0]?.preferredName ? resp[0]?.preferredName.split(', ') : [];
            let userInitials = '';
            if (nameArray.length > 0) {
                userInitials = nameArray.length > 1 ? nameArray[1].charAt(0) + nameArray[0].charAt(0) : nameArray[0].charAt(0);
            }
            setUserDetails(
                {
                    nameInitials: userInitials,
                    bgColor: getRandomColor()
                });
            setUserProfile(profilePicture);
            setUserName(resp[0]?.firstName?.concat(' ').concat(resp[0]?.lastName));
            setUserDesg(resp[0]?.businessTitle);
            setUserEmail(resp[0]?.email);
            setUserPhone(resp[0]?.phones?.phoneNumber)
        }
    }

    return (
        <div className="userprofile__wrap">
            <div className="profile--head">Your Profile</div>
            <div className="profile--subhead">Your profile details are displayed here but aren&apos;t editable. Need to make changes? Head over to your <span className="knprofile">KN Profile.</span></div>
            <div className="profile__body">
                <div className="profile--pic">
                    {
                        userProfile ?
                            <Image src={userProfile} alt='userProfile' className="" />
                            :
                            <span style={{ background: userDetails.bgColor }} className={`user--initialIcon user--img custom--profile--pic`}>
                                {userDetails.nameInitials?.length > 0 ? userDetails.nameInitials : 'N/A'}
                            </span>
                    }
                </div>
                <div className="profile__data">
                    <div className="user--name">
                        {userName}
                    </div>
                    <div className="user--des">
                        {userDesg}
                    </div>
                    <div className="user__emailwrap">
                        <div className="user--email"><span className="email--mention"><Image src={MentionIcon} alt={MentionIcon} className="" /></span>{userEmail}</div>
                        <div className="user--phone"><span className="phone--mention"><Image src={PhoneIcon} alt={PhoneIcon} className="" /></span>{userPhone || '+412-675325790'}</div>
                    </div>
                    <div className="user__analytics">
                        <div className="cases__wrap">
                            <div className="cases--img">
                                <Image src={caseIcon} alt={caseIcon} className="" />
                            </div>
                            <div className="cases__data">
                                <div className="cases--count">
                                    25
                                </div>
                                <div className="cases--text">
                                    Total Cases
                                </div>
                            </div>
                        </div>

                        <div className="cases__wrap">
                            <div className="cases--img">
                                <Image src={caseIcon} alt={caseIcon} className="" />
                            </div>
                            <div className="cases__data">
                                <div className="cases--count">
                                    17
                                </div>
                                <div className="cases--text">
                                    Cases Completed
                                </div>
                            </div>
                        </div>

                        <div className="cases__wrap">
                            <div className="cases--img">
                                <Image src={LikeIcon} alt={LikeIcon} className="" />
                            </div>
                            <div className="cases__data">
                                <div className="cases--count">
                                    320
                                </div>
                                <div className="cases--text">
                                    Interviews Completed
                                </div>
                            </div>
                        </div>

                        <div className="cases__wrap">
                            <div className="cases--img">
                                <Image src={ExpertProfile} alt={ExpertProfile} className="" />
                            </div>
                            <div className="cases__data">
                                <div className="cases--count">
                                    287
                                </div>
                                <div className="cases--text">
                                    Experts Interviewed
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
