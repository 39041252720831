import React, { useState, useEffect } from "react";
import { Layout } from "containers/Layout";
import BreadCrumbs from "components/shared/BreadCrumbs/index";
import SettingsIcon from 'assets/images/noti-settings.svg';
import ProfileIcon from 'assets/images/user-profile.svg';
import FaqIcon from 'assets/images/faq.svg';
import Image from "components/shared/Image";
import { UserProfile } from 'components/UserProfile';
import { Faq } from 'components/Faq';
import { useHistory } from "react-router-dom";
import './SettingsWrapper.scss';

export const SettingsWrapper = () => {
    const history = useHistory();
    const [activeComponent, setActiveComponent] = useState(null);
    let requestPaths = [{
        active: false,
        pathname: '/',
        name: 'Home'
    }, {
        active: true,
        pathname: '/settings/profile',
        name: 'Notification Settings'
    }];

    const settingsData = [{
        icon: ProfileIcon,
        headtext: 'Your Profile',
        path: '/settings/profile'
    },
    {
        icon: FaqIcon,
        headtext: 'FAQs',
        path: '/settings/faq'
    },
    {
        icon: SettingsIcon,
        headtext: 'Notification Settings',
        path: '/settings/notifications'
    }];

    useEffect(() => {
        const path = window.location.pathname;
        switch (path) {
            case '/settings/profile':
                setActiveComponent(<UserProfile />);
                break;
            case '/settings/faq':
                setActiveComponent(<Faq />); // Replace with your FAQ component
                break;
            case '/settings/notifications':
                setActiveComponent(<div>Notification Settings Component</div>); // Replace with your Notification Settings component
                break;
            default:
                setActiveComponent(<div>Default Component</div>); // Replace with a default component or handle the case as needed
        }
    }, [window.location.pathname]);

    return (
        <Layout id="chat" layoutClass="layout__mynavigator">
            <div className="settings__wrapper">
                <div className="settings__pagehead">
                    <BreadCrumbs paths={requestPaths} />
                </div>
                <div className="settings__body">
                    <div className="settings__bodyHead">
                        <div className="manage--acc">Manage Your Account</div>
                        <div className="settings__options">
                            {
                                settingsData?.map((item, index) => {
                                    const isActive = window.location.pathname === item?.path;
                                    return (
                                        <div
                                            className={`settings--item ${isActive ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => history.push(item?.path)}
                                        >
                                            <Image src={item?.icon} className='icon' alt={item?.headtext} />
                                            {item?.headtext}
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>
                    <div className="settings__pagedata">
                        {/* <div className="page--head">Notification Settings</div> */}
                        {activeComponent}
                    </div>
                </div>
            </div></Layout>
    )
}