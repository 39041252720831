import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import './UserSettings.scss';

export const UserSettings = ({ toggleProfilePopup }) => {
    const history = useHistory(null);

    return (
        <div className="userprofile__wrap">
            <div className="profile--item" onClick={() => {
                history.push('/settings/profile');
                toggleProfilePopup();
            }}>
                Your Profile
            </div>
            <div className="profile--item" onClick={() => {
                history.push('/settings/faq');
                toggleProfilePopup();
            }}>
                FAQs
            </div>
        </div>
    );
};
