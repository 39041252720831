import React, { useState, useEffect, useRef, useContext } from 'react';
import { Image, Button } from 'semantic-ui-react';
import DownArrow from "assets/images/down_arrow.svg";
import AddMoreIcon from "assets/images/add_more.svg";
import RemoveIcon from "assets/images/remove_icon.svg";
import './ResourceManager.scss';
import { FormContext } from 'context/form-context';

export const ResourceManager = ({ selectedProvidersList, savedDetails, isDisabled }) => {
    const popupRef = useRef(null);

    const formContext = useContext(FormContext);

    const [selectedProvidersData, setSelectedProvidersData] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [resManagerObj, setResManagerObj] = useState([{
        resManager: '',
        email: ''
    }]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [popupDropDownOptions, setPopupDropDownOptions] = useState(['None']);

    useEffect(() => {
        if (selectedProvidersList || savedDetails) {
            const savedProviders = [...selectedProvidersList];
            if (savedDetails) {
                savedDetails.forEach((p) => {
                    if (p?.name && !savedProviders.includes(p?.name)) {
                        savedProviders.push(p.name);
                    }
                });
            }
            setSelectedProviders(savedProviders);
        }
    }, [selectedProvidersList, savedDetails]);

    useEffect(() => {
        if (selectedProviders?.length > 0) {
            setSelectedProvidersData(selectedProviders);
            setPopupDropDownOptions(['None', ...selectedProviders]);

            let resManagerObjData = resManagerObj;

            let resManagerObjTemp = resManagerObjData.filter(obj => selectedProviders.includes(obj.resManager));
            setResManagerObj([...resManagerObjTemp, { resManager: '', email: '' }]);

            let resManagers = resManagerObjData.map(obj => obj.resManager);
            let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
            if (resManagerObjTemp?.length > 0) {
                if (filteredSelectedProviders?.length > 0)
                    setActiveIndex(resManagerObjTemp?.length);
                else {
                    setActiveIndex(null);
                    setResManagerObj([...resManagerObjTemp]);
                }
            }
            else if (resManagerObj?.length == 2) {
                setActiveIndex(0);
            }
            resManagers = resManagerObj.map(obj => obj.resManager);
            // Filtering selectedProviders to contain only those values not present in resManagers
            filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));

            setPopupDropDownOptions(['None', ...filteredSelectedProviders]);
        }
    }, [selectedProviders, savedDetails]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow--img') && !event.target.classList.contains('dropdown--textbox')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (resManagerObj.length > 0) {
            let tempProviderData = [];
            resManagerObj.forEach((obj) => {
                if (obj.email || obj.resManager) {
                    tempProviderData.push(obj);
                }
            });
            formContext.toggleFormValues('resourceManager', tempProviderData);
        }
    }, [resManagerObj]);

    const handleChange = (event, index, field) => {
        const { value } = event.target;
        const updatedResManagerObj = [...resManagerObj];
        updatedResManagerObj[index][field] = value;

        if (value) {
            // Email validation logic
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(value);

            if (field === 'email' && !isValidEmail) {
                // If the entered email is not valid, set an error flag to true
                updatedResManagerObj[index].error = true;
            } else {
                // If the entered email is valid, remove the error flag
                updatedResManagerObj[index].error = false;
            }
        } else {
            // If email is available as this field is not mandatory.
            updatedResManagerObj[index].error = false;
        }

        setResManagerObj(updatedResManagerObj);
    };

    // const handleSelectProvider = (event, index, field) => {
    //     const { outerText } = event.target;
    //     const updatedResManagerObj = [...resManagerObj];
    //     updatedResManagerObj[index][field] = outerText;
    //     if (savedDetails?.length > 0 && formContext.formView == 'edit') {
    //         const savedRec = savedDetails.find((p) => p.name == outerText);
    //         if (savedRec) {
    //             updatedResManagerObj[index]['email'] = savedRec.researchManagerEmail;
    //         } else {
    //             updatedResManagerObj[index]['email'] = '';
    //         }
    //     } else {
    //         updatedResManagerObj[index]['email'] = '';
    //     }
    //     setResManagerObj(updatedResManagerObj)
    //     setIsPopupOpen(false);
    //     // Filter out the dropdown options that are already selected in other input fields
    //     const dropdownOptions = selectedProvidersData.filter(option =>
    //         !updatedResManagerObj.some(obj => obj.resManager === option)
    //     );
    //     setPopupDropDownOptions(dropdownOptions);
    // }
    const handleSelectProvider = (event, index, field) => {
        const { outerText } = event.target;
        const updatedResManagerObj = [...resManagerObj];

        // Check if 'None' is selected
        if (outerText === 'None') {
            updatedResManagerObj[index][field] = ''; // Revert to initial state
            updatedResManagerObj[index]['email'] = '';
        } else {
            updatedResManagerObj[index][field] = outerText;
            if (savedDetails?.length > 0 && formContext.formView == 'edit') {
                const savedRec = savedDetails.find((p) => p.name == outerText);
                if (savedRec) {
                    updatedResManagerObj[index]['email'] = savedRec.researchManagerEmail;
                } else {
                    updatedResManagerObj[index]['email'] = '';
                }
            } else {
                updatedResManagerObj[index]['email'] = '';
            }
        }

        setResManagerObj(updatedResManagerObj)
        setIsPopupOpen(false);

        // Filter out the dropdown options that are already selected in other input fields
        const dropdownOptions = selectedProvidersData.filter(option =>
            !updatedResManagerObj.some(obj => obj.resManager === option)
        );
        // setPopupDropDownOptions(dropdownOptions);
        setPopupDropDownOptions(['None', ...dropdownOptions]); // Keep 'None' in the options
    }
    const handleAddMore = (index) => {
        if (resManagerObj[index].resManager !== '') {
            if (index === activeIndex && popupDropDownOptions?.length !== 0) {
                // If the "Add More" button is clicked, add a new row
                setResManagerObj([...resManagerObj, { resManager: '', email: '' }]);
                setActiveIndex(index + 1);
            } else {
                // If the "Remove" button is clicked, remove the corresponding row
                const updatedResManagerObj = resManagerObj.filter((_, idx) => idx !== index);
                if (updatedResManagerObj.length === 1) {
                    setResManagerObj([{ resManager: updatedResManagerObj[0]?.resManager, email: updatedResManagerObj[0]?.email }]);
                    setActiveIndex(0);
                    let resManagers = updatedResManagerObj.map(obj => obj.resManager);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(['None', ...filteredSelectedProviders]);

                } else if (updatedResManagerObj.length === 0) {
                    setResManagerObj([{ resManager: '', email: '' }]);
                    setActiveIndex(0);
                    let resManagers = updatedResManagerObj.map(obj => obj.resManager);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(['None', ...filteredSelectedProviders]);
                }
                else {
                    setResManagerObj(updatedResManagerObj);
                    setActiveIndex(updatedResManagerObj?.length - 1);
                    let resManagers = updatedResManagerObj.map(obj => obj.resManager);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(['None', ...filteredSelectedProviders]);
                }
            }
        }
    };
    const handleEmailClass = (item) => {
        if (isDisabled) {
            return 'input-fields disabled form-control'
        }
        else if (item.error) {
            return 'input-fields form-control is-resinvalid is-invalid'
        }
        else {
            return 'input-fields form-control'
        }
    }
    return (
        <div className='resourceManager__wrap'>
            <div className='resourceManager__subwrap'>
                {resManagerObj.map((item, index) => (
                    <div className='resourceManager__body' key={index}>
                        <div className="resourceManager__input-email width70">
                            <div className="input__wrapper">
                                <input type='text'
                                    className={`field-border ${isDisabled ? "disabled" : "dropdown--textbox"} ${activeIndex > index && "disabled"}`}
                                    disabled={isDisabled || activeIndex > index}
                                    htmlFor="providersData" readOnly placeholder='Select from Added Network'
                                    value={item?.resManager}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (activeIndex <= index) {
                                            setActiveIndex(index);
                                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                        }
                                    }}></input>
                                <Image className={`arrow--img ${isDisabled && "imgOpacity"} ${activeIndex > index && "imgOpacity"}`} src={DownArrow} alt="DownArrow" onClick={(event) => {
                                    event.stopPropagation();
                                    if (activeIndex <= index) {
                                        setActiveIndex(index);
                                        setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                    }
                                }
                                } />
                                {
                                    isPopupOpen && activeIndex === index &&
                                    <div className="provider__popup" ref={popupRef}>
                                        <ul>
                                            {
                                                popupDropDownOptions?.map((data, popupIndex) => (
                                                    <li key={popupIndex} onClick={(event) => handleSelectProvider(event, index, 'resManager')}>{data}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className='email__wrapper'>
                                <input type='email'
                                    className={handleEmailClass(item)}
                                    disabled={isDisabled}
                                    value={item.email}
                                    htmlFor="providersData" placeholder='Enter email address of preferred RM' onChange={(event) => handleChange(event, index, 'email')}></input>
                                {item.error && <span className="invalid-feedback error--messag">Please enter valid email address.</span>}
                            </div>
                        </div>

                        <Button className={isDisabled ? "addmore__btn imgOpacity" : "addmore__btn"} onClick={() => !isDisabled && handleAddMore(index)}>
                            <Image className="arrow--img" src={index === activeIndex && selectedProviders?.length - 1 != activeIndex ? AddMoreIcon : RemoveIcon} alt="AddMoreIcon" />
                            {index === activeIndex && selectedProviders?.length - 1 != activeIndex ? "Add More" : "Remove"}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}